import axios from "axios";

export const newTurnsSimpleMixin = {
    data: function () {
        return {
            turns: [], // Предполагается, что в этой переменной хранятся ходы
            order: 'desc', // Предполагается, что в этой переменной хранится порядок сортировки ходов
            currentTurnChannel: null,
            isSimpleMode: false,
            newTurnsProcessDate: null,
            newTurnsLastDate: null,
            newTurnsInterval: 30000,
            newTurnsSimpleInterval: 2500,
            newTurnsIsLoading: false,
            processNewTurnsStarted: false, // Признак, что обновление работает
            processNewTurnsDestroyed: false
        }
    },
    computed: {
        /**
         * Нужно ли в принципе грузить новые сообщения
         * @returns {boolean}
         */
        needListenNewTurns() {
            return true; // переопределить, если не всегда нужно слушать
        },

        /**
         * API URL для загрузки новых ходов
         * @returns {string}
         */
        newTurnsApiUrl() {
            return ''; // переопределить для конкретного url
        }
    },
    created() {
        this.newTurnsLastDate = Date.now();
        this.newTurnsProcessDate = Date.now();
        this.processNewTurns();
        // Через 5 секунд проверяем, не нужно ли включить простой режим
        setTimeout(this.checkSimpleMode, 5000);
    },
    destroyed() {
        // Если уничтожено, то больше не будет работать таймаут совсем
        this.processNewTurnsDestroyed = true;
    },
    methods: {
        checkSimpleMode() {
            if (!Echo.connector.socket.connected && !this.isSimpleMode) {
                console.log('Включаем упрощенный режим ходов, хур-хур, эт самое...');
                this.isSimpleMode = true;
            }
        },

        /**
         * Признак того, что нужно загрузить новые сообщения.
         * Текущее время - из-за кеширования (не реактивно из Date.now()).
         * @param currentTime
         * @returns {boolean}
         */
        needNewTurns(currentTime) {
            return this.processNewTurnsStarted
                && this.needListenNewTurns
                && (
                    !this.newTurnsProcessDate
                    || (
                        currentTime - this.newTurnsProcessDate > (
                            this.isSimpleMode
                                ? this.newTurnsSimpleInterval
                                : this.newTurnsInterval
                        )
                    )
                );
        },

        /**
         * Цикл обработки загрузки новых сообщений
         */
        processNewTurns() {
            if (this.needNewTurns(Date.now())) {
                this.newTurnsProcessDate = Date.now();
                this.fetchNewTurns();
            }
            // Цикл работает каждую секунду. При сворачивании тормозится, но в needNewTurns будет старая дата,
            // так что запустится сразу при открывании окна
            if (!this.processNewTurnsDestroyed) {
                setTimeout(this.processNewTurns, 1000);
            }
        },

        /**
         * Загрузка новых сообщений через API
         */
        fetchNewTurns() {
            // Дата, после которой грузить сообщения
            let lastDate = Date.now();
            this.newTurnsIsLoading = true;

            axios.post(this.newTurnsApiUrl, {
                date: this.newTurnsLastDate
            }).then((response) => {
                var turns = response.data.turns;
                // Иногда приходит не массив, а объект?
                if (turns instanceof Object) {
                    turns = Object.values(turns);
                }

                if (turns.length) {
                    this.addNewTurns(turns);
                }
                this.newTurnsLastDate = lastDate;
            }).catch((error) => {
                let message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                this.$store.dispatch('loading/error', message);
            }).then(() => {
                this.newTurnsIsLoading = false;
            });
        },

        /**
         * Добавить массив новых ходов. С учетом возможных дубликатов.
         * @param turns
         * @param toStart Добавлять ли в начало
         */
        addNewTurns(turns, toStart = true) {
            // Создаем массив с уникальными и отсортированными элементами
            // Сначала добавленные новые - на случай обновления. Обычно эта функция вызывается при прокрутке чата вверх.
            // Всё-таки если в чат свежее сообщение приходит, оно через addMessage идёт обычно
            if (toStart) {
                turns = turns.concat(this.turns);
            } else {
                turns = this.turns.concat(turns);
            }
            turns = turns.filter((item, index) => turns.findIndex(turn => turn.id === item.id) === index);

            let isDesc = this.order === 'desc';
            // Сортируем от старых к новым (или новых к старым)
            turns = turns.sort((a, b) => {
                let comparison = 0,
                    aValue = this.dateFromTurnFormat(a.created_at),
                    bValue = this.dateFromTurnFormat(b.created_at);
                if (aValue > bValue) {
                    comparison = isDesc ? -1 : 1;
                } else if (aValue < bValue) {
                    comparison = isDesc ? 1 : -1;
                } else if (a.id > b.id) { // При равенстве сравниваем id
                    comparison = isDesc ? -1 : 1;
                } else if (a.id < b.id) {
                    comparison = isDesc ? 1 : -1;
                }
                return comparison;
            });

            this.turns = turns;
        },

        /**
         * Дата из формата хода
         * @param strDate
         * @returns {Date}
         */
        dateFromTurnFormat(strDate) {
            return new Date(strDate.replace(/(\d{2})\.(\d{2})\.(\d{4}), (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5'))
        },

        /**
         * Обнуляет время последнего запроса в Простом режиме,
         * чтобы в следующую же секунду были запрошены новые сообщения.
         * Обычно нужно после отправки сообщения.
         */
        fetchNewTurnsNow: function () {
            if (this.isSimpleMode) {
                this.newTurnsProcessDate = null;
            }
        },

        listenSimple() {
            this.processNewTurnsStarted = true;
            //console.log('start simple ' + this._uid);
        },

        leaveSimple() {
            this.processNewTurnsStarted = false;
            //console.log('end simple ' + this._uid);
        }
    }
};
