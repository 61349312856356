<template>
    <div>
        <div class="content mb-3" v-html="trans('navs.login_desc')"></div>

        <div class="mt-2 mb-2 d-flex justify-content-center">
            <a href="" @click.prevent="login" class="btn btn-primary" role="button">{{ trans('navs.login_button') }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                dismissSecs: 5,
                dismissCountDown: 0,
                messageVariant: '',
                message: '',
                form: { username: '', password: ''}
            };
        },
        computed: {
            isAuthenticated: function () {
                return this.$store.getters['auth/isAuthenticated']
            },
            token: function () {
                return this.$store.getters['auth/getToken']
            },
            redirect: function () {
                return this.$store.getters['auth/getRedirect']
            },
        },
        methods: {
            trans (key) {
                return this.$t(key);
            },

            login() {
                this.$store.dispatch('auth/login', {
                    successCallback: this.showSuccess,
                    errorCallback: this.showError
                });
            },

            showSuccess() {
                this.showMessage('success', 'Успешный вход!');
                var redirect = this.redirect;
                this.$store.dispatch('auth/flushRedirect');
                this.$router.push(redirect, () => window.location.reload()); // Обновить страницу после логина - фикс для подтягивания и подключения к сокету
            },
            showError() {
                this.showMessage('danger', 'Ошибка! Возможно, вы ввели неверные данные.');
            },
            showMessage(variant, message) {
                this.messageVariant = variant;
                this.message = message;
                this.dismissCountDown = this.dismissSecs;
                //console.log(message);
            },

        },
        mounted() {
        }
    }
</script>
