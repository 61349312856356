<template>
    <b-list-group flush class="chat-popup-users-online">
      <b-list-group-item><h5 class="mb-1">{{ trans('chat.online') }}</h5></b-list-group-item>
        <b-media tag="li" :key="index" v-for="(userOnline, index) in usersOnline">
            <template v-slot:aside>
              <b-avatar badge variant="light" badge-variant="success" :src="userOnline.avatar ? userOnline.avatar : (userOnline.type === 'master' ? serverUrl + setting('masterAvatar') : serverUrl + setting('defaultAvatar'))" class="channel-image" :alt="(userOnline.type === 'master' ? trans('master.comment_name') : userOnline.name)" :to="userOnline.type === 'profile' && currentGame.profile_show ? { name: 'profiles.show', params: { id: userOnline.id, seo: userOnline.seo } } : null" :href="userOnline.type === 'user' && userOnline.url ? userOnline.url : null"></b-avatar>
            </template>
            <h5 class="mt-1">{{ userOnline.type === 'master' ? trans('master.comment_name') : userOnline.name }}</h5>
        </b-media>
    </b-list-group>
</template>
<script>
    //import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import DraggablePanel from './../DraggablePanel';

    export default {
        name: "PopupOnline",
        components: {
            //FontAwesomeIcon,
            VuePerfectScrollbar,
            DraggablePanel
        },
        props: {
            channel: { required: true }
        },
        computed: {
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            usersOnline () {
                return this.$store.getters['chat/usersOnline'](this.channel) ? this.$store.getters['chat/usersOnline'](this.channel) : [];
            },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
        },
        methods: {
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            trans (key) {
                return this.$t(key);
            },
        },
    }
</script>

<style scoped>

</style>
