<template>
    <div class="card-turn my-3 p-3 bg-rpg-light rounded box-shadow">
        <loading v-if="isLoading || isLoadingForm"></loading>
        <b-form v-else @submit.prevent="makeTurn">
            <div class="media d-block d-md-flex">
                <div v-if="(currentChar && currentChar.turnAvatar) || isGameMaster" class="mb-3">
                    <toggle-button v-if="isGameMaster"
                            id="masterModeToggle"
                            :class="{'mb-3': currentChar}"
                            @change="toggleMode"
                            :color="{checked: '#dc3545', unchecked: '#654321'}"
                            :sync="true"
                            :labels="{checked: trans('turn.seasons'), unchecked: trans('turn.npc')}"
                            :width="100"
                            :height="30"
                            :value="seasonsMode"
                    />
                    <b-avatar v-if="currentChar && currentChar.turnAvatar" :src="currentChar.turnAvatar" rounded size="6rem" :alt="currentChar.name" class="mb-1 media-img d-flex mx-auto turn-avatar"></b-avatar>
                    <button v-if="npcMode && npc && npcList && npcList.length > 1" @click.prevent="changeNpc" class="d-flex mx-auto btn btn-danger mt-3">{{ trans('turn.buttons.change_npc') }}</button>
                    <input type="hidden" name="npc" v-model="npcInput" v-if="npcMode && npc">
                </div>


                <div class="media-body turn-body ml-md-3 ml-0" v-if="currentCharacter && currentCharacter.confirmed">
                    <h5 v-if="currentChar || isGameMaster" class="mt-0 text-center text-md-left font-weight-bold">
<!--                        <a v-if="currentChar && currentChar.url" :href="currentChar.url">{{ currentChar.name }}</a>-->
                        <a v-if="currentChar" >{{ currentChar.name }}</a>

                        <div v-if="isGameMaster && seasonsMode">
                              <red-multiselect v-model="quests" :placeholder="trans('turn.choose_quests')" :options="questList" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="true" option-label="title" option-value="id" option-image="img"></red-multiselect>
                              <input type="hidden" name="quests[]" :value="quest.id" v-for="quest in quests">
                        </div>
                    </h5>

                   <div class="row mt-3" v-if="npcMode && !npc && npcList && npcList.length > 1">
                        <div class="col-md-12">
                            <div class="form-group">
                                <red-multiselect v-model="npc" :placeholder="trans('turn.choose_npc')" :options="npcList" :option-height="70" :show-labels="false" option-label="name" option-value="id" option-image="smallImg"></red-multiselect>
                            </div>
                        </div>
                   </div>

                    <turn-ckeditor v-if="showInterface" class="form-control" v-model="body" name="body" :save-function="autoSaveCallback" :upload-url="imageUploadUrl" :enable-images="isGameMaster" :autofocus="'autofocus'"></turn-ckeditor>
                    <textarea @click.prevent="initInterface" class="form-control" required="required" rows="4" name="body" cols="50" v-else>{{ htmlToPlainText(body) }}</textarea>

                    <div class="row mt-3" v-show="showInterface">
                        <div class="col-12">
                            <div id="complexity" class="progress red-progress bg-rpg-primary">
                                <div class="progress-bar bg-danger" role="progressbar" :style="'width: '+progress.shortSize+'%'" :aria-valuenow="progress.shortSize" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg-warning" role="progressbar" :style="'width: '+progress.mediumSize+'%'" :aria-valuenow="progress.mediumSize" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg-success" role="progressbar" :style="'width: '+progress.longSize+'%'" :aria-valuenow="progress.longSize" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="red-progress-title">{{ progress.title }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" v-if="showCurrentLocation" v-show="showInterface">
                      <div class="col-md-12">
                        <div class="form-group">
                          <red-multiselect id="currentLocation" v-model="currentLocation" :options="currentLocations" field="currentLocation" required :placeholder="trans('turn.currentLocation')" option-label="title" option-value="key" :allow-empty="false"></red-multiselect>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-show="showInterface">
                        <div class="col-12">
                          <button @click.prevent="switchShowAction" class="btn btn-primary mt-3" v-if="currentChar">{{ showAction ? trans('action.remove_action') : trans('action.add_action') }}</button>
                            <b-button variant="primary" class="float-right mt-3" type="submit" :disabled="(npcMode && (!currentChar)) || isMakingTurn || !body" id="makeTurn">
                              <b-spinner small v-if="isMakingTurn"></b-spinner>
                              <span class="sr-only" v-if="isMakingTurn">Загрузка...</span>
                              {{ trans('turn.buttons.send') }}
                            </b-button>
                          <b-button variant="primary" v-b-modal="rollModalId" class="btn btn-primary mt-3 mr-1 float-right" :disabled="!!body" v-if="isGameMaster && seasonsMode"><font-awesome-icon :icon="['far', 'dice']" fixed-width></font-awesome-icon></b-button>
                        </div>
                    </div>

                     <div class="row mt-3" v-if="showAction && currentChar" v-show="showInterface">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="action" class="control-label">{{ trans('action.title') }}</label>
                                <red-multiselect id="action" v-model="action" :placeholder="trans('action.title')" :options="actionTypes" :option-height="70" :show-labels="false" :custom-label="customLabel" option-label="title" option-value="key" option-image="img"></red-multiselect>
                                <input type="hidden" name="action" v-model="actionInput">
                            </div><!--form control-->
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" v-if="actionIsRoute">
                                <label for="location" class="control-label">{{ trans('location.title') }}</label>
                                <red-multiselect id="location" v-model="location" :placeholder="trans('location.title')" :options="locations" :option-height="70" :show-labels="false" :custom-label="customLabel" option-label="title" option-value="key" option-image="image"></red-multiselect>
                                <input type="hidden" name="location" v-model="locationInput">
                            </div><!--form control-->
                        </div>
                     </div>
                </div>
                <div class="media-body turn-body ml-md-3 ml-0" v-else>{{ trans('turn.not_confirmed') }}</div>
            </div>
        </b-form>
        <roll-dice-window
            :modal-id="rollModalId"
            v-if="isMaster"
            :send-roll="rollDice"
        ></roll-dice-window>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import TurnCkeditor from "./TurnCkeditor";
    import { ToggleButton } from 'vue-js-toggle-button';
    import RedMultiselect from "@/components/includes/RedMultiselect";
    import Loading from "@/components/includes/Loading";
    import RollDiceWindow from "./includes/RollDiceWindow.vue";

    export default {
        components: {
            RollDiceWindow,
          Loading,
          RedMultiselect,
            TurnCkeditor,
            FontAwesomeIcon,
            ToggleButton
        },

        data() {
            return {
                quote: null,
                timeDelay: 300,
                loading: true,
                showAction: false,
                showInterface: false, // Показывать ли основную часть интерфейса для хода
                //editor: null,
                //textLength: 0,
                progress: {
                    title: '',
                    shortSize: 0,
                    mediumSize: 0,
                    longSize: 0
                },
                action: null,
                location: null,
                npc: null,
                lastNpc: null,
                quests: [],
                seasonsMode: true,
                body: null,
                currentLocation: null, // Текущее местоположение - для выбора базовой локации или отображения (или читерского перехода для праздников)
                npcList: [],
                questList: [],
                actionTypes: [],
                locationRoutes: [],
                isLoadingForm: false,
                isMakingTurn: false,
                initialized: false, // Признак, что инициализация прошла
                firstSelectLocation: true, // Признак первой локации - тут надо иначе инициализировать мультиселект, не объектом, а ключом
                firstSelectMasterLocation: true // Специально для мастера и NPC
            };
        },

        props: {
            //body: { default: '' },
            oldAction: { default: null },
            oldLocation: { default: null },
            addFormAction: { default: '' },
            //char: { default: () => {}},
            //isGameMaster: { default: false },
            //isGameTech: { default: false },
            saveSuffix: { default: 'turn' },
            showCurrentLocation: { type: Boolean, default: false },
            pageLocation: { default: null } // Локация со страницы - когда нет выпадающего списка
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.prepareComponent();
        },

        watch: {
            currentCharacter(newChar, oldChar) {
              // Если выбрали персонажа, или персонажа с другим ключом
              if (newChar && (!oldChar || newChar.key !== oldChar.key)) {
                this.prepareComponent();
              }
            },
            body () {
                this.rpgcomplex(this.htmlToPlainText(this.body).length);
            },
            npc () {
              if (this.npc) {
                if (+this.npc.id !== +this.masterSettings.npc) {
                  this.setMasterSetting('npc', this.npc.id);
                }

                if (this.npc.location && this.currentLocations.find(location => location.key === this.npc.location)) {
                  this.currentLocation = this.firstSelectMasterLocation ? this.npc.location : this.currentLocations.find(location => location.key === this.npc.location);
                }

                if (this.npc.locationRoutes) {
                  this.locationRoutes = this.npc.locationRoutes;
                }

                if (this.firstSelectMasterLocation) {
                  this.firstSelectMasterLocation = false;
                }
              }
            },
            seasonsMode() {
              if (this.seasonsMode !== this.masterSettings.seasonsMode) {
                this.setMasterSetting('seasonsMode', this.seasonsMode);
              }
            },
            quests() {
              if (this.isGameMaster && this.seasonsMode && this.initialized) {
                var quests = this.quests.map(quest => quest instanceof Object ? quest.id : quest).join(',');
                if (quests !== this.masterSettings.quests) {
                  this.setMasterSetting('quests', quests);
                }
              }
            },
            currentLocation() {
              if (this.isGameMaster && this.seasonsMode && this.currentLocation && this.currentLocation.key !== this.masterSettings.location) {
                this.setMasterSetting('location', this.currentLocation.key);
              }
            }
        },

        methods: {
            trans (key) {
                return this.$t(key);
            },

            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            /**
             * Prepare the component (Vue 2.x).
             */
            prepareComponent() {
                this.currentLocation = null;
                // Проверка и загрузка необходимых данных для формы
                this.checkFormData();
                // Смена состояния select
                this.firstSelectLocation = true;
                this.firstSelectMasterLocation = true;
            },

            initInterface() {
                this.showInterface = true;
            },

            htmlToPlainText(html) {
                var dom = document.createElement("Div");
                dom.innerHTML = html;
                return (dom.textContent || dom.innerText);
            },

            checkOldValues() {
                if (this.autoSaveKey && localStorage.getItem(this.autoSaveKey) && localStorage.getItem(this.autoSaveKey) !== 'null') {
                    this.body = localStorage.getItem(this.autoSaveKey);
                } else {
                    this.body = '';
                }

                // делать ли...
                if (this.oldAction && this.actionTypes) {
                  this.action = this.actionTypes.find(type => type.key === this.oldAction);
                } else {
                  this.action = null;
                }
                if (this.oldLocation && this.locations) {
                  this.location = this.locations.find(loc => loc.key === +this.oldLocation);
                } else {
                  this.location = null;
                }

                // Если было действие, то включаем кнопку
                if (this.action) {
                  this.showAction = true;
                } else {
                  this.showAction = false;
                }
            },

            rpgcomplex(length) {
                var size = 100;
                if (length === 0)
                {
                    this.progress = { title: this.trans('action.post_length.input'), shortSize: 0, mediumSize: 0, longSize: 0 };
                } else {
                    if (length < 330)
                    {
                        size = Math.round(length / 10);

                        this.progress = { title: this.trans('action.post_length.short'), shortSize: size, mediumSize: 0, longSize: 0 };
                    }
                    else if (length >= 330 && length <= 700)
                    {
                        size = Math.round((length - 330) / 10);

                        this.progress = { title: this.trans('action.post_length.medium'), shortSize: 33, mediumSize: size, longSize: 0 };
                    }
                    else if (length > 700)
                    {
                        size = Math.round((length - 700) / 10);
                        if (size > 30) size = 30.01;

                        this.progress = { title: this.trans('action.post_length.long'), shortSize: 33, mediumSize: 37, longSize: size };
                    }
                }
            },

            switchShowAction() {
                this.showAction = !this.showAction;
            },

            customLabel ({ title }) {
                return `${title}`
            },

            initGameTech() {
                if (this.npcList.length > 0) {
                  if (this.masterSettings.npc) {
                    this.npc = this.npcList.find(npc => +npc.id === +this.masterSettings.npc);
                  }
                  if (!this.npc) {
                    this.npc = this.npcList[0];
                  }
                }
            },

            initGameMaster() {
                this.lastNpc = null;
                this.seasonsMode = this.masterSettings.seasonsMode !== undefined ? this.masterSettings.seasonsMode : true;
                this.quests = this.masterSettings.quests ? this.questList.filter(quest => this.masterSettings.quests.split(',').indexOf('' + quest.id) !== -1).map(quest => quest.id) : [];
                this.setGameMasterMode();
            },

            toggleMode() {
                this.seasonsMode = !this.seasonsMode;
                this.setGameMasterMode();
            },

            /**
             * Инициализирует мастерское окружение - в зависимости от включенного режима сезонов.
             */
            setGameMasterMode() {
              if (this.seasonsMode) {
                if (this.npc) {
                  this.lastNpc = this.npc;
                }
                this.changeNpc();

                if (this.masterSettings.location) {
                  var currentLocation = this.currentLocations.find(location => location.key === this.masterSettings.location);
                  if (currentLocation) {
                    this.currentLocation = this.firstSelectMasterLocation ? currentLocation.key : currentLocation;
                  }
                }
                if (this.firstSelectMasterLocation) {
                  this.firstSelectMasterLocation = false;
                }
              }
              else if(this.npcList.length > 0) {
                if (this.lastNpc) {
                  this.npc = this.lastNpc;
                } else {
                  if (this.masterSettings.npc) {
                    this.npc = this.npcList.find(npc => +npc.id === +this.masterSettings.npc);
                  }
                  if (!this.npc) {
                    this.npc = this.npcList[0];
                  }
                }
              }
            },

            changeNpc() {
                this.npc = null;
            },

            autoSaveCallback(data) {
                return new Promise( resolve => {
                    localStorage.setItem(this.autoSaveKey, data);
                    resolve();
                } )
            },

            makeTurn: function () {
                if (this.isMakingTurn) {
                  return;
                }

                this.isMakingTurn = true;

                setTimeout(() => {
                    // Запаздывает похоже обновление из модели из ckeditor (по крайней мере пока), буду вызывать отложенно, чтобы не оптправилось неполное сообщение.
                    this.axios.post('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key, this.sendData).then( (response) => {
                        //this.emptyNewMessage();
                        // Создаем событие - если кто-то слушает извне
                        this.$emit('sent');
                        // Очищаем сохранение, если отправлено и всё ок
                        this.autoSaveCallback('');
                        this.body = '';
                        this.showAction = false;
                        // инициализация - на основе тех данных, что пришли с сервера
                        this.initFormData(response.data, true);
                        //this.$router.push({ name: 'main' });
                    }).catch((error) => {
                        var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                        if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                            message = message.errors.body[0];
                        }
                        this.$store.dispatch('loading/error', message);
                        //this.$store.dispatch('loading/stop', null);
                    }).then(() => {
                        this.isMakingTurn = false;
                    });
                }, 300);
            },

            locationInfo (locationKey) {
              return locationKey ? this.$store.getters['rpg/location'](locationKey) : {};
            },

            /**
             * Инициализация только после завершения прошлых загрузок
             */
            checkFormData() {
              if (!this.isLoading) {
                this.loadFormData();
              } else {
                setTimeout(this.checkFormData, 100);
              }
            },

            /**
             * Загрузка информации по ходу персонажа
             */
            loadFormData() {
              this.actionTypes = null;
              this.locationRoutes = null;
              this.questList = null;
              this.npcList = null;

              //this.$store.dispatch('loading/start', 'Загрузка данных формы...', { root: true });
              this.isLoadingForm = true;

                this.axios.get('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key)
                  .then((response) => {
                    //this.$store.dispatch('loading/stop', null, { root: true });
                    // инициализация
                    this.initFormData(response.data);
                  }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    //this.$store.dispatch('loading/stop', null);
                    // context.rootState.router.push('login');
                  })
                  .then(() => {
                    this.isLoadingForm = false;
                  });
            },
            /**
             * Инициализация данных формы после загрузки информации о ходе данного пользователя.
             */
            initFormData (data, fromTurn = false) {
              if (!fromTurn) {
                this.actionTypes = data.actionTypes;
                this.questList = data.questList;
                this.npcList = data.npcList;
              }

              this.locationRoutes = data.locationRoutes;
              this.currentLocation = data.currentLocation || (this.currentLocation instanceof Object ? this.currentLocation.key : this.currentLocation); // Сначала выбираем ключ

              // Если указан NPC - меняем локацию для него
              if (this.currentLocation && this.npc && this.currentLocation !== this.npc.location) {
                //this.moveCurrentNPC(this.currentLocation);
                this.npc.locationRoutes = this.locationRoutes;
                this.npc.location = this.currentLocation;
              } else if (this.currentLocation && this.currentLocation !== this.currentCharacter.location) {
                this.moveCurrentCharacter(this.currentLocation);
              }

              // Информация о текущей локации - для игр, где это можно изменить (праздники, первый ход в ролевой)
              if (this.currentLocations.length) {
                if (!this.currentLocation) {
                  this.currentLocation = this.currentLocations[0].key;
                }
              }

              // Если не первая, то меняем на объект
              if (!this.firstSelectLocation) {
                this.currentLocation = this.currentLocations.find(location => location.key === this.currentLocation)
              } else {
                this.firstSelectLocation = false;
              }

              // Устанавливаем базовые проверки, которые ранее были в prepareComponent
              if (!fromTurn) {
                this.checkOldValues();
                if (this.isGameTech) {
                  this.initGameTech();
                }
                else if (this.isGameMaster) {
                  this.initGameMaster();
                }

                this.initialized = true;
              }
            },

            /**
             * Переместить персонажа в другую локацию
             */
            moveCurrentCharacter (locationKey) {
              this.$store.dispatch('rpg/moveCurrentCharacter', locationKey);
            },

            /**
             * Записать настройку для мастера
             */
            setMasterSetting (key, value) {
              this.$store.dispatch('rpg/setGameSettings', { master: Object.assign(this.masterSettings, { [key]: value}) });
            },

            rollDice(rollCommand, closeCallback) {
                if (!this.isMaster) {
                    return;
                }

                // this.axios.post('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id, {
                //     message: rollCommand
                // }).then((response) => {
                //
                // }).catch((error) => {
                //     let message = error.response.data.error ? error.response.data.error : error.response.data;
                //     this.$store.dispatch('loading/error', message);
                //     this.$store.dispatch('loading/stop', null);
                // });


                setTimeout(() => {
                    // Запаздывает похоже обновление из модели из ckeditor (по крайней мере пока), буду вызывать отложенно, чтобы не оптправилось неполное сообщение.
                    this.axios.post('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/roll', {
                        location: this.showCurrentLocation
                            ? (this.currentLocation instanceof Object ? this.currentLocation.key : this.currentLocation)
                            : this.pageLocation,
                        roll: rollCommand
                    }).then( (response) => {
                        // Создаем событие - если кто-то слушает извне (обычно - чтобы сразу запросить обновление ходов)
                        this.$emit('sent');
                        // инициализация - на основе тех данных, что пришли с сервера (вдруг поменялась локация за это время, МАЛО ЛИ)
                        this.initFormData(response.data, true);
                        // Hide the modal manually
                        if (closeCallback) {
                            closeCallback();
                        }
                    }).catch((error) => {
                        var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                        if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                            message = message.errors.body[0];
                        }
                        this.$store.dispatch('loading/error', message);
                        //this.$store.dispatch('loading/stop', null);
                    });
                }, 300);
            }
        },

        computed: {
            actionInput() {
                return this.action ? this.action.key : null;
            },

            locationInput() {
                return this.location ? this.location.id : null;
            },

            actionIsRoute() {
                return this.action ? this.action.key === 'route' : false;
            },

            actionIsRoll() {
                return this.action ? this.action.key === 'roll' : false;
            },

            npcInput() {
                return this.npc ? this.npc.id : null;
            },

            questsInput() {
                return this.quests ? this.quests.map(e => e.id).join(",") : null;
            },

            currentChar() {
                if (this.isGameMaster || this.isGameTech) {
                    return this.npc;
                } else {
                    return this.currentCharacter;
                }
            },

            npcMode() {
                return this.isGameTech || (this.isGameMaster && !this.seasonsMode);
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            autoSaveKey () {
                return 'rpg.game.' + this.currentGame.key + '.char.' + this.currentCharacter.key + '.' + this.saveSuffix;
            },

            isGameMaster () {
                return this.isMaster && this.currentCharacter.isGameMaster;
            },

            isGameTech () {
                return this.isMaster && this.currentCharacter.isGameTech;
            },

            gameLocations () {
                return this.$store.getters['rpg/locations'];
            },

            /**
             * Возможные значения для локаций.
             * Либо одно из базовых значений (если текущая локация еще не выбрана или если есть флаг игры).
             * Либо только одно текущее.
             */
            currentLocations () {
              if (this.isGameMaster) {
                return Object.values(this.gameLocations);
              } else if (!this.currentCharacter.location || this.currentGame.choose_turn_location) {
                return this.currentGame.baseLocations.map(locationKey => this.locationInfo(locationKey)).filter(location => !!location);
              } else if (this.currentCharacter.location) {
                return [this.locationInfo(this.currentCharacter.location)];
              } else {
                return [];
              }
            },

            /**
             * Признак загрузки. Если установлен флаг или если не загружены данные о локации (если их нужно показывать).
             * @returns {boolean|*}
             */
            isLoading() {
              return this.$store.getters['loading/isLoading'] || this.isLoadingForm || (this.showCurrentLocation && !this.$store.getters['rpg/locationsSet']);
            },

            /**
             * Локации, в которые можно перейти из текущей
             * @returns {*|null}
             */
            locations() {
              return this.locationRoutes
                  ? this.locationRoutes.map(locationKey => this.locationInfo(locationKey)).filter(location => !!location)
                  : [];
            },

            /**
             * Объект для отправки хода
             * @returns {{body: null, currentLocation: (*|null)}}
             */
            sendData() {
              var sendData = {
                body: this.body,
                currentLocation: this.showCurrentLocation
                    ? (this.currentLocation instanceof Object ? this.currentLocation.key : this.currentLocation)
                    : this.pageLocation
              };

              if (this.action) {
                sendData.action = this.action.key;
              }

              if (this.location) {
                sendData.location = this.location.key;
              }

              if (this.isMaster) {
                if (!this.npcMode) {
                  if (this.quests) {
                    sendData.quests = this.quests.map(quest => quest.id);
                  }
                } else {
                  if (this.npc) {
                    sendData.npc = this.npc.id;
                  }
                }
              }

              return sendData;
            },

            /**
             * Настройки, связанные с мастером
             * @returns {*}
             */
            masterSettings () {
              return this.$store.getters['rpg/gameSetting']('master') || {};
            },

            imageUploadUrl() {
                return process.env.VUE_APP_SERVER_URL + '/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/image';
            },

            rollModalId() {
              return 'turnRollWindowModal-' + this.currentGame.key;
            },
        }
    }
</script>

<style scoped>

</style>
