<template>
    <div>
        <router-link v-if="showLocation && location && !previousLocationIsEqual" :to="{ name: 'location', params: { currentLocationKey: location.key } }" tag="div" class="card-rpg-header my-3 p-2 text-rpg-light bg-rpg-primary rounded box-shadow is-clickable">
            <div class="media d-block d-flex">
                <b-avatar :src="location.image" rounded size="3rem" :alt="location.title"></b-avatar>
                <div class="media-body ml-3">
                    <h5 class="mt-2 mb-0 text-left redfont">
                        {{ location.title }}
                    </h5>
                </div>
            </div>
        </router-link>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="descOrder" v-for="action in turn.actions">
              <span v-if="actionIsRoute(action)">
                <b-avatar :src="char.img" rounded size="sm" :alt="char.name"></b-avatar> <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="charIsProfile && currentGame.profile_show">{{ char.name }}</router-link><span v-else>{{ char.name }}</span> {{ trans('action.went_to.' + char.gender) }} {{ trans('action.to_location') }} <router-link v-if="action.to && gameLocations && gameLocations[action.to]" :to="{ name: 'location', params: { currentLocationKey: gameLocations[action.to].key } }">{{ gameLocations[action.to].title }}</router-link>
              </span>
              <span v-else-if="actionIsRoll(action)">
                  <h5 v-if="turn.created_at" class="mt-0 font-weight-bold hide-mobile float-right" style="min-height: 1rem;"><span class="pull-right small text-muted">{{ turn.created_at }}</span></h5>
                  <roll-dice-message :message="action.roll"></roll-dice-message>
              </span>
        </div>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else-if="!descOrder && turn.actionFrom">
            <b-avatar :src="char.img" rounded size="sm" :alt="char.name"></b-avatar> <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="charIsProfile && currentGame.profile_show">{{ char.name }}</router-link><span v-else>{{ char.name }}</span> {{ trans('action.went_from.' + char.gender) }} {{ trans('action.from_location') }} <router-link v-if="turn.actionFrom && gameLocations && gameLocations[turn.actionFrom]" :to="{ name: 'location', params: { currentLocationKey: gameLocations[turn.actionFrom].key } }">{{ gameLocations[turn.actionFrom].title }}</router-link>
        </div>

        <loading v-if="isLoadingDelete"></loading>
        <div class="card-turn my-3 p-3 bg-rpg-light rounded box-shadow" v-else-if="turn.post">
            <div class="media d-block d-md-flex turn-block">
                 <span v-if="char || canEdit || turn.created_at" class="mb-1 mb-md-3 d-flex d-md-block">
                    <b-avatar v-if="char" :id="'turn-avatar-' + turn.id" :badge="isOnline(char)" badge-variant="success" :src="char.img" rounded size="6rem" :alt="char.name" class="mb-1 turn-avatar small-badge"></b-avatar>
                    <span class="d-flex mx-auto flex-column hide-mobile" v-if="char && char.type == 'profile'">
                        <span class="turn-under-container d-flex mb-1">
                            <img class="mr-1 mx-auto turn-under-image rounded" :src="char.faction.img" :alt="char.faction.title" data-toggle="tooltip" data-placement="bottom" :title="char.faction.title">
                            <img class="mx-auto turn-under-image rounded" :src="char.species.img" :alt="char.species.title" data-toggle="tooltip" data-placement="bottom" :title="char.species.title">
                            <span class="mx-auto d-flex align-items-center turn-under-image turn-gender rounded" data-toggle="tooltip" data-placement="bottom" :title="trans('genders.' + char.gender)">
                                <font-awesome-icon class="mx-auto" :icon="genderIcon" size="lg"></font-awesome-icon>
                            </span>
                        </span>
                         <span class="d-flex mb-1">
                            <span class="mx-auto" data-toggle="tooltip" data-placement="left" :title="trans('profile.age')">{{ char.age }}</span>
                         </span>
                    </span>
                    <b-popover :target="'turn-avatar-' + turn.id" v-if="char && char.type == 'profile'">
                        <span class="turn-under-container d-flex mb-1">
                            <img class="mr-1 mx-auto turn-under-image rounded" :src="char.faction.img" :alt="char.faction.title" data-toggle="tooltip" data-placement="bottom" :title="char.faction.title">
                            <img class="mx-auto turn-under-image rounded" :src="char.species.img" :alt="char.species.title" data-toggle="tooltip" data-placement="bottom" :title="char.species.title">
                            <span class="mx-auto d-flex align-items-center turn-under-image turn-gender rounded" data-toggle="tooltip" data-placement="bottom" :title="trans('genders.' + char.gender)">
                                <font-awesome-icon class="mx-auto" :icon="genderIcon" size="lg"></font-awesome-icon>
                            </span>
                        </span>
                         <span class="d-flex mb-1">
                            <span class="mx-auto" data-toggle="tooltip" data-placement="left" :title="trans('profile.age')">{{ char.age }}</span>
                         </span>
                    </b-popover>
                    <div v-if="char || canEdit || turn.created_at" class="mt-0 font-weight-bold mobile-turn-info show-mobile">
                        <a @click.prevent="switchIsComment" class="btn pull-right pt-0 pl-0" v-if="canComment && !isEdit">
                            <font-awesome-icon icon="comments"/> <span v-if="turn.comments && turn.comments.length > 0">({{ turn.comments.length }})</span>
                        </a>
                        <a @click.prevent="switchIsEdit" class="btn pull-right pt-0 pl-0" v-if="canEdit && !isEdit && !isComment">
                            <font-awesome-icon icon="pencil"/>
                        </a>
                        <a @click.prevent="removeTurnConfirm" class="btn pull-right pt-0 pl-0" v-if="canDelete && !isEdit && !isComment">
                            <font-awesome-icon icon="trash"/>
                        </a>
                        <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="char && charIsProfile && currentGame.profile_show">
                            {{ char.name }}
                        </router-link>
                        <a v-else-if="char" :href="char.url">{{ char.name }}</a>
                        <div class="small text-muted" v-if="!isEdit">{{ turn.created_at }}</div>
                    </div>
                 </span>

                <span v-else-if="!charIsMaster">{{ trans('guest') }}</span>
                <div class="media-body ml-md-3 ml-0">
                    <h5 v-if="char || canEdit || turn.created_at" class="mt-0 font-weight-bold hide-mobile" style="min-height: 1rem;">
                        <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="charIsProfile && currentGame.profile_show">
                            {{ char.name }}
                        </router-link>
                        <a v-else-if="char" :href="char.url">{{ char.name }}</a>
                        <span class="pull-right small text-muted" v-if="!isEdit">{{ turn.created_at }}</span>
                        <a @click.prevent="switchIsComment" class="btn pull-right pt-0 pl-0" v-if="canComment && !isEdit">
                            <font-awesome-icon icon="comments"/> <span v-if="turn.comments && turn.comments.length > 0">({{ turn.comments.length }})</span>
                        </a>
                        <a @click.prevent="switchIsEdit" class="btn pull-right pt-0 pl-0" v-if="canEdit && !isEdit && !isComment">
                            <font-awesome-icon icon="pencil"/>
                        </a>
                        <a @click.prevent="removeTurnConfirm" class="btn pull-right pt-0 pl-0" v-if="canDelete && !isEdit && !isComment">
                            <font-awesome-icon icon="trash"/>
                        </a>
                    </h5>

                    <span class="turn-content" v-html="turn.post" v-show="!isEdit"/>
                    <span v-show="isEdit" v-if="canEdit">
                        <form method="POST" @submit.prevent="editTurn">
                            <turn-ckeditor class="form-control" v-model="body" :name="'turn_'+turn.id" :save-function="autoSaveCallback" :autofocus="'autofocus'"></turn-ckeditor>

                            <div class="progress red-progress bg-rpg-primary mt-3">
                                <div class="progress-bar bg-danger" role="progressbar" :style="'width: '+progress.shortSize+'%'" :aria-valuenow="progress.shortSize" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg-warning" role="progressbar" :style="'width: '+progress.mediumSize+'%'" :aria-valuenow="progress.mediumSize" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg-success" role="progressbar" :style="'width: '+progress.longSize+'%'" :aria-valuenow="progress.longSize" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="red-progress-title">{{ progress.title }}</div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <b-button variant="danger" @click.prevent="switchIsEdit" class="mt-3">{{ trans('turn.buttons.cancel') }}</b-button>
                                    <b-button variant="primary" type="submit" class="float-right mt-3">
                                        <b-spinner small v-if="isLoadingEdit"></b-spinner>
                                        <span class="sr-only" v-if="isLoadingEdit">Загрузка...</span>
                                        {{ trans('turn.buttons.save') }}
                                    </b-button>
                                </div>
                            </div>
                        </form>
                    </span>

                    <span v-show="isComment" v-if="canComment">
                        <h5 class="mt-3 text-center text-md-left font-weight-bold">
                            {{ trans('turn.comments') }}
                        </h5>

                        <loading v-if="isLoadingComments"></loading>
                        <transition-group name="list" tag="div">
                            <div class="card-turn my-3 p-3 bg-rpg-light rounded box-shadow" v-for="comment in turn.comments" v-if="!isLoadingComments && turn.comments" :key="comment.id">
                                <div class="media d-block d-md-flex">
                                    <b-avatar v-if="comment.author === 'owner' && char" :src="char.img" rounded size="lg" :alt="char.name" class="mb-1 turn-avatar"></b-avatar>
                                    <div class="media-body ml-md-3 ml-0">
                                        <h5 class="mt-0 text-center text-md-left font-weight-bold">
                                            <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="comment.author === 'owner' && char && charIsProfile && currentGame.profile_show" class="small">{{ char.name }}</router-link>
                                            <span v-else-if="comment.author === 'owner' && char" class="small">{{ char.name }}</span>
                                            <span v-else-if="comment.author === 'master'" class="small">{{ trans('master.comment_name') }}</span>
                                            <span class="pull-right small text-muted">{{ comment.created_at }}</span>
                                        </h5>

                                        <span class="turn-content" v-html="comment.body"/>
                                    </div>
                                </div>
                            </div>
                        </transition-group>

                        <form method="POST" @submit.prevent="commentTurn">
                            <turn-ckeditor class="form-control" v-model="commentBody" :name="'comment_turn_'+turn.id" :save-function="autoSaveCommentCallback" :autofocus="'autofocus'"></turn-ckeditor>
                            <div class="row">
                                <div class="col-12">
                                    <b-button variant="danger" @click.prevent="switchIsComment" class="mt-3">{{ trans('turn.buttons.cancel') }}</b-button>
                                    <b-button variant="primary" type="submit" class="float-right mt-3">
                                        <b-spinner small v-if="isLoadingComment"></b-spinner>
                                        <span class="sr-only" v-if="isLoadingComment">Загрузка...</span>
                                        {{ trans('turn.buttons.comment') }}
                                    </b-button>
                                </div>
                            </div>
                        </form>
                    </span>
                    <div class="reaction" v-if="canReact || turn.reactions.length">
                        <b-spinner small v-if="isLoadingReaction"></b-spinner>
                        <a @click.prevent="react" :class="{ react: canReact }" v-else><font-awesome-icon :icon="['far', 'fire']" size="lg"></font-awesome-icon></a>
                        <b-badge v-if="turn.reactions.length" variant="primary" :id="'reactions-turn-' + turn.id">{{ turn.reactions.length }}</b-badge>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="descOrder && turn.actionFrom">
          <b-avatar :src="char.img" rounded size="sm" :alt="char.name"></b-avatar> <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="charIsProfile && currentGame.profile_show">{{ char.name }}</router-link><span v-else>{{ char.name }}</span> {{ trans('action.went_from.' + char.gender) }} {{ trans('action.from_location') }} <router-link v-if="turn.actionFrom && gameLocations && gameLocations[turn.actionFrom]" :to="{ name: 'location', params: { currentLocationKey: gameLocations[turn.actionFrom].key } }">{{ gameLocations[turn.actionFrom].title }}</router-link>
        </div>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else-if="!descOrder" v-for="action in turn.actions">
            <span v-if="actionIsRoute(action)">
                <b-avatar :src="char.img" rounded size="sm" :alt="char.name"></b-avatar> <router-link :to="{ name: 'profiles.show', params: { id: char.id, seo: char.seo } }" v-if="charIsProfile && currentGame.profile_show">{{ char.name }}</router-link><span v-else>{{ char.name }}</span> {{ trans('action.went_to.' + char.gender) }} {{ trans('action.to_location') }} <router-link v-if="action.to && gameLocations && gameLocations[action.to]" :to="{ name: 'location', params: { currentLocationKey: gameLocations[action.to].key } }">{{ gameLocations[action.to].title }}</router-link>
            </span>
        </div>

        <b-popover :target="'reactions-turn-' + turn.id" triggers="hover focus" placement="top" custom-class="red-popover" v-if="turn.reactions.length">
            <b-avatar-group rounded="circle" size="25px" class="mt-2" :overlap="turn.reactions.length > 1 ? 0.3 : 0">
                <b-avatar :badge="isOnline(reaction.char)" variant="secondary" badge-variant="success" :src="reaction.char.img" :alt="reaction.char.name" v-for="reaction in turn.reactions" :key="reaction.char.id" v-b-tooltip.hover.top="{ title: reaction.char.name, variant: 'secondary',  boundary: 'window' }" class="channel-image small-badge" :to="reaction.char.type === 'profile' && currentGame.profile_show ? { name: 'profiles.show', params: { id: reaction.char.id, seo: reaction.char.seo } } : null" :href="reaction.char.type === 'user' && reaction.char.url ? reaction.char.url : null"></b-avatar>
            </b-avatar-group>
        </b-popover>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import TurnCkeditor from "./TurnCkeditor";
    import Loading from "./includes/Loading";
    import RollDiceMessage from "./includes/RollDiceMessage.vue";

    export default {
        components: {
            RollDiceMessage,
            Loading,
            FontAwesomeIcon,
            TurnCkeditor
        },

        data() {
            return {
                timeDelay: 300,
                loading: true,
                isEdit: false,
                isComment: false,
                lastCommentLoadTime: 0,
                loadNewCommentTimeout: 30, // Пока просто раз в 30 сек грузим заново комментарии, если можно и нужно
                //editor: null,
                //commentEditor: null, // Пока скорее чтобы понимать, что он уже инициализирован
                //textLength: 0,
                progress: {
                    title: '',
                    shortSize: 0,
                    mediumSize: 0,
                    longSize: 0
                },
                body: '',
                commentBody: '',
                isLoadingEdit: false,
                isLoadingDelete: false,
                isLoadingComments: false, // загрузка комментариев
                isLoadingComment: false, // отправка нового комментария
                isLoadingReaction: false,
                // action: null,
                // location: null,
            };
        },

        props: {
            turn: { default: () => { actionFrom: {} } },
            // editAction: { default: '' },
            // deleteAction: { default: '' },
            // commentAction: { default: '' },
            showLocation: { type: Boolean, default: false },
            previousTurn: { default: null }, // Прошлый ход - если есть. Может потребоваться для инфы о локациях
            nextTurn: { default: null }, // Следующий ход - если есть. Может потребоваться для инфы о локациях
            order: { default: 'desc' } // Порядок ходов. Для того, чтобы поставить куда надо информацию о действиях.
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.prepareComponent();
        },

        watch: {
            body () {
                this.rpgcomplex(this.htmlToPlainText(this.body).length);
            },
        },

        methods: {
            trans (key) {
                return this.$t(key);
            },

            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            /**
             * Prepare the component (Vue 2.x).
             */
            prepareComponent() {
                //this.checkOldValued();
            },

            htmlToPlainText(html) {
                var dom = document.createElement("Div");
                dom.innerHTML = html;
                return (dom.textContent || dom.innerText);
            },

            // checkOldValued() {
            //     //body - todo может понадобится для связи в ckeditor 5
            //     if (this.oldAction && this.actionTypes) this.action = this.actionTypes.find(type => type.key === this.oldAction);
            //     if (this.oldLocation && this.locations) this.location = this.locations.find(loc => loc.id === +this.oldLocation);
            //
            //     // Если было действие, то включаем кнопку
            //     if (this.action) this.showAction = true;
            // },

            rpgcomplex(length) {
                var size = 100;
                if (length === 0)
                {
                    this.progress = { title: this.trans('action.post_length.input'), shortSize: 0, mediumSize: 0, longSize: 0 };
                } else {
                    if (length < 330)
                    {
                        size = Math.round(length / 10);

                        this.progress = { title: this.trans('action.post_length.short'), shortSize: size, mediumSize: 0, longSize: 0 };
                    }
                    else if (length >= 330 && length <= 700)
                    {
                        size = Math.round((length - 330) / 10);

                        this.progress = { title: this.trans('action.post_length.medium'), shortSize: 33, mediumSize: size, longSize: 0 };
                    }
                    else if (length > 700)
                    {
                        size = Math.round((length - 700) / 10);
                        if (size > 30) size = 30.01;

                        this.progress = { title: this.trans('action.post_length.long'), shortSize: 33, mediumSize: 37, longSize: size };
                    }
                }
            },

            switchIsEdit() {
                this.isEdit = !this.isEdit;
                if (this.isEdit) {
                    this.body = this.turn.post;
                }
            },

            switchIsComment() {
                this.isComment = !this.isComment;
                if (this.isComment/* && this.turn.comments === undefined*/) { // todo - Потом сделать такую проверку, когда будут синхронизироваться редактирования комментариев. Пока удобнее так
                    // Инициализация при открытии
                    if (!this.lastCommentLoadTime) {
                        // Каждые 5 секунд проверяем, не пора ли запросить заново комментарии
                        // (а это уже обычно раз в 30 секунд где-то, но при нажатии на кнопку загрузка также автоматом и счетчик сбрасывается)
                        setInterval(() => {
                            if (/*this.isComment && */(Date.now() - this.lastCommentLoadTime > this.loadNewCommentTimeout * 1000)) { // Пока грузит всегда после первого открытия (чтобы счетчик менялся)
                                this.loadComments();
                            }
                        }, 5000);
                    }
                    this.loadComments();
                }
            },

            actionIsRoute(action) {
                return action ? action.key === 'route' : false;
            },

            actionIsRoll(action) {
                return action ? action.key === 'roll' : false;
            },

            editTurn: function () {
                if (this.isLoadingEdit) {
                    return;
                }

                this.isLoadingEdit = true;

                setTimeout(() => {
                    // Запаздывает похоже обновление из модели из ckeditor (по крайней мере пока), буду вызывать отложенно, чтобы не оптправилось неполное сообщение.
                    this.axios.post('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/edit/' + this.turn.id, {
                        body: this.body,
                        currentLocation: this.turn.location
                    }).then( (response) => {
                        this.body = '';
                        this.turn.post = response.data.turn.post;
                        // Создаем событие - если кто-то слушает извне
                        this.$emit('edited', this.turn);
                        this.$store.dispatch('message/success', response.data.message);
                    }).catch((error) => {
                        var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                        if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                            message = message.errors.body[0];
                        }
                        this.$store.dispatch('loading/error', message);
                    }).then(() => {
                        this.isLoadingEdit = false;
                        this.isEdit = false;
                    });
                }, 300);
            },

            removeTurnConfirm() {
                if (this.isLoadingDelete) {
                    return;
                }

                const h = this.$createElement;
                // Using HTML string
                const messageVNode = h('div', { domProps: { innerHTML: this.$t('turn.confirm_delete') } });
                this.$bvModal.msgBoxConfirm([messageVNode], {
                    okTitle: this.$t('yes'),
                    cancelTitle: this.$t('no'),
                    centered: true
                }).then(value => {
                    if (value) {
                        this.removeTurn();
                    }
                });
            },

            removeTurn: function () {
                if (this.isLoadingDelete) {
                    return;
                }

                this.isLoadingDelete = true;

                this.axios.post('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/delete/' + this.turn.id, {}).then( (response) => {
                    // Создаем событие - если кто-то слушает извне
                    this.$emit('deleted', this.turn);
                    this.$store.dispatch('message/success', response.data.message);
                }).catch((error) => {
                    var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                    if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                        message = message.errors.body[0];
                    }
                    this.$store.dispatch('loading/error', message);
                }).then(() => {
                    this.isLoadingDelete = false;
                    this.isEdit = false;
                });
            },

            autoSaveCallback(data) {
                return new Promise( resolve => {
                    localStorage.setItem(this.autoSaveKey, data);
                    resolve();
                } )
            },

            autoSaveCommentCallback(data) {
                return new Promise( resolve => {
                    localStorage.setItem(this.autoSaveCommentKey, data);
                    resolve();
                } )
            },

            loadComments() {
                this.isLoadingComments = true;

                setTimeout(() => {
                    // Запаздывает похоже обновление из модели из ckeditor (по крайней мере пока), буду вызывать отложенно, чтобы не оптправилось неполное сообщение.
                    this.axios.get('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/comments/' + this.turn.id)
                        .then((response) => {
                            var comments = response.data.comments;
                            // Иногда приходит не массив, а объект?
                            if (comments instanceof Object) {
                                comments = Object.values(comments);
                            }
                            this.addNewComments(comments, false);
                        }).catch((error) => {
                        var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                        if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                            message = message.errors.body[0];
                        }
                        this.$store.dispatch('loading/error', message);
                    })
                        .then(() => {
                            this.lastCommentLoadTime = Date.now();
                            this.isLoadingComments = false;
                        });
                }, 300);
            },

            commentTurn: function () {
                if (this.isLoadingComment) {
                    return;
                }

                this.isLoadingComment = true;

                this.axios.post('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/comment/' + this.turn.id, { body: this.commentBody }).then( (response) => {
                    // Создаем событие - если кто-то слушает извне
                    this.$emit('commented', response.data.comment);
                    // Очищаем сохранение, если отправлено и всё ок
                    this.autoSaveCommentCallback('');
                    this.commentBody = '';

                    this.addComment(response.data.comment);
                    this.$store.dispatch('message/success', response.data.message);
                }).catch((error) => {
                    var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                    if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                        message = message.errors.body[0];
                    }
                    this.$store.dispatch('loading/error', message);
                }).then(() => {
                    this.isLoadingComment = false;
                });
            },

            addComment(comment) {
                this.turn.comments.push(comment);
            },

            /**
             * Добавить массив новых комментариев. С учетом возможных дубликатов.
             * @param comments
             * @param toStart Добавлять ли в начало
             */
            addNewComments(comments, toStart = false) {

                if (this.turn.comments === undefined) {
                    this.$set(this.turn, 'comments', []);
                }

                // Создаем массив с уникальными и отсортированными элементами
                // Сначала добавленные новые - на случай обновления. Обычно эта функция вызывается при прокрутке чата вверх.
                // Всё-таки если в чат свежее сообщение приходит, оно через addMessage идёт обычно
                if (toStart) {
                    comments = comments.concat(this.turn.comments);
                } else {
                    comments = this.turn.comments.concat(comments);
                }
                comments = comments.filter((item, index) => comments.findIndex(comment => comment.id === item.id) === index);

                // Сортируем от старых к новым (или новых к старым)
                comments = comments.sort((a, b) => {
                    let comparison = 0,
                        aValue = a.created,
                        bValue = b.created;
                    if (aValue > bValue) {
                        comparison = 1;
                    } else if (aValue < bValue) {
                        comparison = -1;
                    } else if (a.id > b.id) { // При равенстве сравниваем id
                        comparison = 1;
                    } else if (a.id < b.id) {
                        comparison = -1;
                    }
                    return comparison;
                });

                this.turn.comments = comments;
            },

            react() {
                if (this.isLoadingReaction || !this.canReact) {
                    return;
                }

                this.isLoadingReaction = true;

                this.axios.post('/api/rpg/turn/' + this.currentGame.key + '/' + this.currentCharacter.key + '/react/' + this.turn.id, {}).then( (response) => {
                    // Создаем событие - если кто-то слушает извне
                    this.turn.reactions.push(response.data.reaction);
                    this.$store.dispatch('message/success', response.data.message);
                }).catch((error) => {
                    var message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                    if (message && message.errors && message.errors && message.errors.body && message.errors.body[0]) {
                        message = message.errors.body[0];
                    }
                    this.$store.dispatch('loading/error', message);
                }).then(() => {
                    this.isLoadingReaction = false;
                });
            },

            isOnline (char) {
                return this.$store.getters['rpg/isOnline'](char.id, char.type);
            },
        },

        computed: {
            // actionInput() {
            //     return this.action ? this.action.key : null;
            // },
            genderIcon () {
                if (this.char) {
                    switch(this.char.gender) {
                        case 'male': return 'mars';
                        case 'female': return 'venus';
                        default: return 'question';
                    }
                } else return null;
            },

            char () {
                return this.turn.char;
            },

            charIsMaster () {
                return this.turn.charIsMaster;
            },

            charIsProfile () {
                return this.turn.char && this.turn.char.type === 'profile';
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            previousLocationIsEqual () {
                return this.previousTurn && this.previousTurn.location === this.turn.location;
            },

            location () {
                return this.turn.location ? this.$store.getters['rpg/location'](this.turn.location) : null;
            },

            nextLocationIsEqual () {
                return this.nextTurn && this.nextTurn.location === this.turn.location;
            },

            descOrder () {
              return this.order === 'desc';
            },

            gameLocations () {
              return this.$store.getters['rpg/locations'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            isUserCharacter () {
                return this.$store.getters['rpg/isUserCharacter'];
            },

            isGameMaster () {
                return this.isMaster && this.currentCharacter.isGameMaster;
            },

            isGameTech () {
                return this.isMaster && this.currentCharacter.isGameTech;
            },

            canBeEdited() {
                return ((Date.now() / 1000 - this.turn.created) / 3600) < this.$store.getters['settings/setting']('turnsCanEditHours');
            },

            canEdit () {
                if (this.isMaster) {
                    return (this.isGameMaster || (this.isGameTech && this.turn.isOwner)) && this.canBeEdited;
                } else if (this.isUserCharacter) {
                    return this.turn.isOwner && this.canBeEdited;
                } else if (this.currentCharacter) {
                    return this.char && this.char.id === this.currentCharacter.key && this.canBeEdited;
                }
                return false;
            },

            canBeDeleted() {
                return ((Date.now() / 1000 - this.turn.created) / 3600) < this.$store.getters['settings/setting']('turnsCanDeleteHours');
            },

            canDelete () {
                if (this.isMaster) {
                    return (this.isGameMaster || (this.isGameTech && this.turn.isOwner)) && this.canBeDeleted;
                } else if (this.isUserCharacter) {
                    return this.turn.isOwner && this.canBeDeleted;
                } else if (this.currentCharacter) {
                    return this.char && this.char.id === this.currentCharacter.key && this.canBeDeleted;
                }
                return false;
            },

            canComment () {
                if (this.isMaster) {
                    return true;
                } else if (this.isUserCharacter) {
                    return this.turn.isOwner;
                } else if (this.currentCharacter) {
                    return this.char && this.char.id === this.currentCharacter.key;
                }
                return false;
            },

            autoSaveKey () {
                return 'rpg.game.' + this.currentGame.key + '.char.' + this.currentCharacter.key + '.turn.' + this.turn.id;
            },

            autoSaveCommentKey () {
                return 'rpg.game.' + this.currentGame.key + '.char.' + this.currentCharacter.key + '.turn.' + this.turn.id + '.comment';
            },

            canReact () {
                if (!this.currentCharacter || this.turn.isOwner) {
                    return false;
                }

                let canReact = true;
                this.turn.reactions.forEach(reaction => {
                    if (reaction.isOwner) {
                        canReact = false;
                    }
                })
                return canReact;
            },
        }
    }
</script>

<style scoped>
    .list-item {
        display: block;
    }
    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }

    .turn-block {
        position: relative;
    }

    .reaction {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .reaction .react {
        transition: all 0.3s;
        cursor: pointer;
    }

    .reaction .react:hover {
        font-size: 2em;
    }

    .reaction .badge {
        margin-left: 0.5em;
    }
</style>
