<template>
    <b-card
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white"
        class="chat-side-users-online"
        v-if="usersOnline"
    >
        <vue-perfect-scrollbar>
            <b-list-group flush>
                <b-list-group-item v-b-tooltip.hover.left="{title: (userOnline.type === 'master' ? trans('master.comment_name') : userOnline.name),  boundary: 'window'}" :key="index" v-for="(userOnline, index) in usersOnline" class="list-group-item-action">
                    <b-avatar badge variant="light" badge-variant="success" :src="userOnline.avatar ? userOnline.avatar : (userOnline.type === 'master' ? serverUrl + setting('masterAvatar') : serverUrl + setting('defaultAvatar'))" class="channel-image small-badge" :alt="(userOnline.type === 'master' ? trans('master.comment_name') : userOnline.name)" :to="userOnline.type === 'profile' && currentGame.profile_show ? { name: 'profiles.show', params: { id: userOnline.id, seo: userOnline.seo } } : null" :href="userOnline.type === 'user' && userOnline.url ? userOnline.url : null"></b-avatar>
                </b-list-group-item>
            </b-list-group>
        </vue-perfect-scrollbar>
    </b-card>
</template>
<script>
    //import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import DraggablePanel from './DraggablePanel';

    export default {
        name: "SideOnline",
        components: {
            //FontAwesomeIcon,
            VuePerfectScrollbar,
            DraggablePanel
        },
        computed: {
            usersOnline () {
                return this.$store.getters['rpg/online'];
            },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
        },
        methods: {
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            trans (key) {
                return this.$t(key);
            },
        },
    }
</script>

<style scoped>

</style>
