<template>
    <div>
        <div class="redimage-file-pond">
            <file-pond
                    ref="pond"
                    name="file"
                    :server="server"
                    v-bind="filepondOptions"
                    :allow-multiple="false"
                    accepted-file-types="image/*"
                    :files="oldFiles"
                    @processfile="updateFile"
                    @updatefiles="updateFile"
                    :disabled="readonly"
            >
            </file-pond>
            <button v-if="oldValue && currentValue != oldValue" class="btn btn-rpg-secondary btn-sm redimage-revert-action" :class="{'redimage-revert-action-new': file}" @click.prevent="setOld">
                <font-awesome-icon icon="undo"></font-awesome-icon>
            </button>
        </div>

        <div v-if="errors.length" class="alert alert-warning">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert()">
                <span aria-hidden="true">&times;</span>
            </button>
            <p v-for="error in errors"><font-awesome-icon :icon="['far', 'hand-point-right']"></font-awesome-icon> {{ error }}</p>
        </div>

        <input :name="serverIdName" type="hidden" :value="serverId">
        <input :name="name" type="hidden" :value="currentValue">

        <b-modal
            v-model="showImageEditor"
            :title="$t('profile.image_editor')"
            size="xl"
            centered
            @hidden="editCancel"
            @ok="editOk"
            :cancel-title="$t('form.cancel')"
        >
            <font-awesome-icon icon="spinner" spin size="2x" class="loading" v-show="!visibleImageEditor"></font-awesome-icon>
            <tui-image-editor ref="tuiImageEditor" include-ui :options="editorOptions" v-show="visibleImageEditor"></tui-image-editor>
        </b-modal>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    // Import Vue FilePond
    import vueFilePond from 'vue-filepond';
    // Import image preview and file type validation plugins
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    // Import the plugin code
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize';
    // Toast UI Image Editor
    import { ImageEditor } from '@toast-ui/vue-image-editor';
    import redwallTheme from './../../js/toast-ui-redwall-theme';
    import FilePondPluginFilePoster from "filepond-plugin-file-poster";
    import axios from "axios";

    // Create component
    const FilePond = vueFilePond(
        FilePondPluginImageTransform,
        FilePondPluginImageResize,
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview,
        FilePondPluginFilePoster
    );

    export default {
        name: "AvatarUpload",
        components: {
            FontAwesomeIcon,
            FilePond,
            'tui-image-editor': ImageEditor
        },

        props: {
            oldValue: {
                default: ''
            },
            oldImage: {
                default: ''
            },
            oldServerId: {
                default: ''
            },
            readonly: {
                type: Boolean,
                default: false
            },
            defaultImage: {
                default: process.env.VUE_APP_BASE_URL + '/img/default_photo.png'
            },
            url: {
                default: ''
            },
            urlRevert: {
                default: ''
            },
            urlDefaultAvatar: {
                default: ''
            }
        },
        data () {
            return {
                errors: [],
                uploading: false,
                serverId: null,
                file: null,
                imageSize: 512,
                serverIdName: 'avatar_server_id',
                name: 'avatar',
                showImageEditor: false, // Модель для модального окна
                visibleImageEditor: false, // Признак того, что можно показывать редактор после загрузки картинки
                isFileFromEditor: false // Признак того, что загрузка файла допускается (он после редактора)
            }
        },
        created() {
            this.preLoadDefaultImage();
            // Если есть старый файл, устанавливаем его
            if (this.oldValue && this.oldImage) {
                this.isFileFromEditor = true;
            }
        },
        methods: {
            /**
             * Предварительная загрузка базового изображения, для того, чтобы оно точно было к нужному момент в редакторе
             */
            preLoadDefaultImage() {
                let image = new Image();
                image.src = this.defaultImage;
            },
            setOld () {
                if (this.oldValue && this.oldImage) {
                    this.isFileFromEditor = true;
                    this.$refs.pond.addFile(this.oldImage, this.oldFile.options);
                    this.revertAvatar();
                }
            },
            closeAlert () {
                this.errors = [];
            },
            updateFile() {
                // Файл - на всякий случай, например для определения класса (для которого нужно знать, что файл уже грузится, даже если ещё не загрузился).
                this.file = this.$refs.pond.getFile();
                this.serverId = this.file ? this.file.serverId : null;
            },
            showError(data) {
                if(Array.isArray(data)) {
                    this.errors = data;
                }
                var error;

                if (data instanceof Object || Array.isArray(data)) {
                    error = JSON.stringify(data);
                } else {
                    error = data;
                }

                this.$store.dispatch('message/error', error);
            },
            loadImageToEdit(file) {
                this.showImageEditor = true;
                // Чтобы успела загрузиться оригинальная картинка (успеет с предзагрузкой)
                this.$nextTick(() => {
                        setTimeout(() => {
                            this.visibleImageEditor = true;
                            this.$refs.tuiImageEditor.invoke('loadImageFromFile', file).then(result => {
                                this.$refs.tuiImageEditor.invoke('setCropzoneRect', 1);
                            });
                        }, 1000);
                    });
            },
            cropCurrent() {
                return this.$refs.tuiImageEditor.invoke('crop', this.$refs.tuiImageEditor.invoke('getCropzoneRect'));
            },
            makeFile() {
                return this.$refs.tuiImageEditor.invoke('toDataURL');
            },
            editOk() {
                this.cropCurrent().then(() => {
                    var file = this.makeFile();

                    this.isFileFromEditor = true;
                    this.visibleImageEditor = false;
                    this.$refs.pond.addFile(file);
                });
            },
            editCancel() {
                this.visibleImageEditor = false;
            },
            revertAvatar() {
                axios.post(this.apiUrlRevert, {
                    filename: this.oldValue
                })
                .then((response) => {
                    this.setCharacterData(response.data);
                })
                .catch((error) => {
                    this.showError(error.response.data.message)
                });
            },

            setDefaultAvatar() {
                axios.post(this.apiUrlDefault, {})
                    .then((response) => {
                        this.setCharacterData(response.data);
                    })
                    .catch((error) => {
                        this.showError(error.response.data.message)
                    });
            },

            setCharacterData(data) {
                this.$store.dispatch('rpg/setCharacterData', data);
            }
        },
        computed: {
            oldFiles () {
                return this.oldValue || this.oldServerId ? [
                    this.oldFile
                ] : [];
            },
            oldFile() {
                return this.oldImage || this.oldServerId ? {
                    // the server file reference
                    source: this.oldServerId ? this.oldServerId : this.oldImage,
                    // set type to local to indicate an already uploaded file
                    options: {
                        type: this.oldServerId ? 'limbo' : 'local',
                        metadata: {
                            poster: this.oldImage
                        }
                    }
                } : null;
            },
            filepondOptions() {
                return Object.assign(this.$t('filepond'), {
                    imageResizeTargetWidth: this.imageSize,
                    imageResizeTargetHeight: this.imageSize,
                    imageResizeMode: 'contain',
                    imageResizeUpscale: false,
                    // default crop aspect ratio
                    imageCropAspectRatio: '1:1',
                    // open editor on image drop
                    imageEditInstantEdit: true,
                    beforeAddFile: (item) => {
                        if (this.isFileFromEditor || (this.oldValue && this.currentValue === this.oldValue)) {
                            this.isFileFromEditor = false;
                            return true;
                        } else {
                            this.loadImageToEdit(item.file);
                            return false;
                        }
                    },
                    beforeRemoveFile: (item) => {
                        this.setDefaultAvatar();
                    },
                    labelIdle: `<span class="filepond--label-action btn btn-rpg-primary btn-sm change-photo"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-upload fa-w-16"><path data-v-3804e729="" fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" class=""></path></svg></span>`,
                    imagePreviewHeight: 96,
                    stylePanelLayout: 'compact',
                    styleLoadIndicatorPosition: 'center bottom',
                    styleProgressIndicatorPosition: 'right bottom',
                    styleButtonRemoveItemPosition: 'left bottom',
                    styleButtonProcessItemPosition: 'right bottom',
                });
            },
            editorOptions() {
                return {
                    includeUI: {
                        loadImage: {
                            path: this.defaultImage,
                            name: 'Default Avatar'
                        },
                        theme: redwallTheme,
                        menu: ['crop'],
                        initMenu: 'crop',
                        menuBarPosition: 'bottom',
                        usageStatistics: false
                    },
                    cssMaxWidth: 512,
                    cssMaxHeight: 512
                }
            },
            server() {
                return {
                    url: axios.defaults.baseURL + this.apiUrl,
                    process: {
                        onload: (response) => {
                            if (!response) {
                                return;
                            }
                            let data = JSON.parse(response) ? JSON.parse(response) : response;
                            this.setCharacterData(data);
                        },
                        onerror: (response) => this.showError(JSON.parse(response) ? JSON.parse(response).message : response),
                    },
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters['auth/getToken'],
                        'Accept': 'application/json'
                    },
                };
            },
            currentValue() {
                if (this.oldValue && this.serverId) {
                    if (this.serverId === this.oldImage) {
                        return this.oldValue;
                    }
                }
                return null;
            },

            apiUrl() {
                return this.url ? this.url : '/api/rpg/profile/avatar/upload/' + this.currentGame.key + '/' + this.currentCharacter.key
            },

            apiUrlRevert() {
                return this.urlRevert ? this.urlRevert : '/api/rpg/profile/avatar/revert/' + this.currentGame.key + '/' + this.currentCharacter.key
            },

            apiUrlDefault() {
                return this.urlDefaultAvatar ? this.urlDefaultAvatar : '/api/rpg/profile/avatar/delete/' + this.currentGame.key + '/' + this.currentCharacter.key
            },

            // Специально для определения корректного url при загрузке в профиле
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            }
        }
    }
</script>

<style lang="scss">
    .redimage-file-pond {
        position: relative;
        width: 6rem;
    }

    .redimage-revert-action {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
    }

    .redimage-revert-action-new {
        top: auto;
        bottom: 0;
    }

    .tui-image-editor-container {
        min-height: 70vh;

        .tui-image-editor-main-container {
            border: none !important;
            background-image: none !important;
        }

        .tui-image-editor-header, .tui-image-editor-submenu, .tui-image-editor-controls {
            display: none !important;
        }

        .tui-image-editor-main-container {
            bottom: 0;
        }

        .tui-image-editor-main {
            top: 0;
        }
    }

    [data-filepond-item-state*="busy"] .filepond--progress-indicator svg {
        margin-top: -0.15em;
    }
</style>
