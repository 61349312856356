<template>
    <div :class="{ 'text-center': centered }">
        <b-spinner variant="primary" label="Загрузка"></b-spinner>
    </div>
</template>

<script>
    export default {
        props: {
            centered: { type: Boolean, default: true }
        },
        name: "Loading",
    }
</script>

<style scoped>

</style>
