<template>
    <div class="d-flex justify-content-end" v-if="group">
        <b-form-radio-group
            v-model="gameKey"
            button-variant="outline-primary"
            name="radio-btn-outline"
            buttons
            @change="goToGameRoute(gameKey)"
        >
            <b-form-radio v-for="option in options" :value="option.value">
                <font-awesome-icon :icon="[option.icon.type, option.icon.icon]" fixed-width v-if="option.icon"></font-awesome-icon>
                {{ option.text }}
            </b-form-radio>
        </b-form-radio-group>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: "GameGroupSwitch",
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            gameKey: null
        }
    },
    mounted () {
        if (this.currentGame && this.group) {
            this.gameKey = this.currentGame.key;

            if (this.isHoliday && this.firstFeast && this.currentGame.key !== this.firstFeast.key && this.needHolidaySwitch()) {
                // Проверяем. Если сейчас праздник, и у нас есть в группе есть хотя бы один праздник, перенаправляем на первый
                this.lastHolidaySwitch = Date.now();
                this.goToGameRoute(this.firstFeast.key);
            } else if (!this.isHoliday && this.currentGame.status === 'holiday' && this.firstNonFeast && this.needHolidaySwitch()) {
                // Наоборот, если у нас не праздник, а сейчас открыт праздник, и среди группы есть активный не праздник - перенаправляем на первый не-праздник
                this.lastHolidaySwitch = Date.now();
                this.goToGameRoute(this.firstNonFeast.key);
            }

            // todo - если будет на странице с локациями - можно также проверять, есть ли такие локации в других группах. Если нет, то не редиректить туда
        }
    },
    watch: {
        currentGame (val, oldVal) {
            if (val && oldVal && val.key !== oldVal.key && val.key !== this.gameKey) {
                this.gameKey = val.key;
            }
        }
    },
    methods: {
        getGame (key) {
            return this.$store.getters['rpg/getGame'](key);
        },

        goToGameRoute (gameKey) {
            if (gameKey !== this.currentGame.key) {
                this.$router.push({ ...this.$route, params: { ...this.$route.params, game: gameKey } });
            }
        },

        needHolidaySwitch () {
            return !this.lastHolidaySwitch || (Date.now() - this.lastHolidaySwitch > this.secondsTillDayEnds * 1000); // Задержка до следующей проверки о необходимости изменения ролевой на другую группу. Пока пусть будет час. UPD. До конца текущего дня
        },

        secondsTillDayEnds () {
            let d = new Date();
            return (24*60*60) - (d.getHours()*60*60) - (d.getMinutes()*60) - d.getSeconds();
        }
    },
    computed: {
        currentGame () {
            return this.$store.getters['rpg/currentGame'];
        },

        isHoliday () {
            return this.$store.getters['settings/setting']('isHoliday');
        },

        group () {
            return this.currentGame.group;
        },

        options () {
            let options = [];
            if (this.group && this.group.games) {
                this.group.games.forEach(gameKey => {
                    let game = this.getGame(gameKey);
                    if (game) {
                        options.push({ text: game.title, value: game.key, icon: game.icon })
                    }
                });
            }
            return options;
        },

        firstFeast () {
            let game = null;
            if (this.group && this.group.games) {
                this.group.games.forEach(gameKey => {
                    let curGame = this.getGame(gameKey);
                    if (curGame && curGame.status === 'holiday') {
                        game = curGame;
                    }
                });
            }
            return game;
        },

        firstNonFeast () {
            let game = null;
            if (this.group && this.group.games) {
                this.group.games.forEach(gameKey => {
                    let curGame = this.getGame(gameKey);
                    if (curGame && curGame.status === 'active') {
                        game = curGame;
                    }
                });
            }
            return game;
        },

        lastHolidaySwitch: {
            get() {
                return +localStorage.getItem('redwall.rpg.gamegroup.' + this.group.key + '.lastHolidaySwitch');
            },
            set (newVal) {
                localStorage.setItem('redwall.rpg.gamegroup.' + this.group.key + '.lastHolidaySwitch', newVal)
            }
        }
    },
}
</script>

<style scoped>

</style>
