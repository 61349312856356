<template>
  <b-list-group flush>
    <b-list-group-item><h5 class="mb-1">{{ $t('chat.send_settings') }}</h5></b-list-group-item>
    <b-list-group-item>
      <b-form-radio-group
          v-model="sendSetting"
          :options="sendSettingOptions"
          stacked
      ></b-form-radio-group>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: "PopupSettings",
  components: {
    //FontAwesomeIcon
  },
  mounted() {
    if (!this.sendSetting) {
      this.sendSetting = this.isMobile ? 'ctrlEnter' : 'enter';
    }
  },
  computed: {
    serverUrl() {
      return this.$store.getters['rpg/serverUrl'];
    },
    isMobile() {
      return this.$store.getters['auth/isMobile'];
    },
    sendSetting: {
      get () {
        var sendSetting = this.$store.getters['auth/setting'](this.isMobile ? 'sendSettingMobile' : 'sendSetting');
        if (!sendSetting) {
          sendSetting = this.isMobile ? 'ctrlEnter' : 'enter';
        }
        return sendSetting;
      },
      set (value) {
        this.$store.dispatch('auth/setSetting', { key: this.isMobile ? 'sendSettingMobile' : 'sendSetting', value: value});
      }
    },
    sendSettingOptions() {
      return [
        { html: this.$t('chat.send_enter') + '</br>', value: 'enter' },
        { html: this.$t('chat.send_ctrl_enter'), value: 'ctrlEnter' },
      ];
    }
  },
  methods: {
    setting(key) {
      return this.$store.getters['settings/setting'](key);
    },
  },
}
</script>

<style lang="scss">
.custom-radio:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
