<template>
    <div>
        <b-card
                no-body
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="chat-channels-full mt-2 mb-2"
                v-if="channelsIsActive"
        >
            <header slot="header">
                <router-link class="btn btn-primary" :to="{ name: 'main' }">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" class="sidebar-icon"/>
                </router-link>
                <b class="card-header-title">{{ trans('chat.title') }}</b>
            </header>

            <b-list-group flush>
                <b-list-group-item :key="index" v-for="(channelItem, index) in channels" @click="chooseChannel(channelItem)" class="list-group-item-action">
                    <b-media>
                        <template v-slot:aside>
                          <b-avatar :badge="channelItem.unreads ? '' + channelItem.unreads : false" variant="light" badge-variant="primary" :src="channelItem.image ? channelItem.image : serverUrl + setting('defaultAvatar')" class="channel-image" :alt="channelItem.title"></b-avatar>
                        </template>

                        <h5 class="mt-0">{{ channelItem.title }}</h5>
                        <p>

                        </p>
                    </b-media>
                </b-list-group-item>
            </b-list-group>
        </b-card>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else>
            {{ trans('error.chat_empty') }}
        </div>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import DraggablePanel from "../../components/includes/DraggablePanel";

    export default {
        name: "Channels",
        components: {
            FontAwesomeIcon,
            VuePerfectScrollbar,
            DraggablePanel
        },
        data: function () {
            return {
                // edit: false,
                // newMessage: { id: '', author_id: '', author_type: '', message: '', channel_key: '', channel_type: '', game_key: ''},
            }
        },
        computed: {
            user () {
                return this.$store.getters['auth/getUser'];
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            channels () {
                return this.$store.getters['chat/channels'];
            },
            channelsIsActive() {
                return this.$store.getters['chat/channelsIsActive'];
            },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },
        },
        methods: {
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            trans (key) {
                return this.$t(key);
            },

            chooseChannel: function (channel) {
                this.$router.push({ name: 'channel', params: { channelType: channel.type, channelId: channel.id } });
            },

        },
    }
</script>

<style scoped>

</style>
