import Vue from 'vue';

export const redPopover = {
    namespaced: true,

    state: {
        models: {},
        keys: {},
        titles: {},
        requestStatuses: {}
    },
    getters: {
        // Массивы собраны в один объект (поиск в них по типу-ключу)
        modelsByType: (state) => (type) => {
            if (!state.models.hasOwnProperty(type)) {
                Vue.set(state.models, type, {});
            }
            return state.models[type];
        },

        keysByType: (state) => (type) => {
            if (!state.keys.hasOwnProperty(type)) {
                Vue.set(state.keys, type, {});
            }
            return state.keys[type];
        },

        emptyKeysArrayByType: (state, getters) => (type) => {
            var keysObject = getters.keysByType(type),
                result = [];

            Object.keys(keysObject).forEach(function (field) {
                // Только те, что false
                if (!keysObject[field]) {
                    result.push(field);
                }
            });

            return result;
        },

        titlesByType: (state) => (type) => {
            if (!state.titles.hasOwnProperty(type)) {
                Vue.set(state.titles, type, {});
            }
            return state.titles[type];
        },

        emptyTitlesArrayByType: (state, getters) => (type) => {
            var titlesObject = getters.titlesByType(type),
                result = [];

            Object.keys(titlesObject).forEach(function (field) {
                // Только те, что null
                if (!titlesObject[field]) {
                    result.push(field);
                }
            });

            return result;
        },

        requestStatusByType: (state) => (type) => {
            if (!state.requestStatuses.hasOwnProperty(type)) {
                Vue.set(state.requestStatuses, type, false);
            }
            return state.requestStatuses[type];
        },

        // Модифицированные старые функции

        checkKey: (state, getters) => (type, key) => {
            let foundKey = Object.keys(getters.keysByType(type)).find(modelKey => modelKey === key);
            if (foundKey)
                return foundKey;
            else
                return getters.byKey(type, key);
        },

        checkTitle: (state, getters) => (type, title) => {
            let foundTitle = Object.keys(getters.titlesByType(type)).find(modelTitle => modelTitle.indexOf(title) !== -1);
            if (foundTitle)
                return foundTitle;
            else
                return getters.byTitle(type, title);
        },

        byKey: (state, getters) => (type, key) => {
            if (getters.modelsByType(type).hasOwnProperty(key)) {
                return getters.modelsByType(type)[key];
            }
            return null;
        },

        byTitle: (state, getters) => (type, title) => {
            if (getters.titlesByType(type).hasOwnProperty(title)) {
                let key = getters.titlesByType(type)[title];
                if (key && getters.modelsByType(type).hasOwnProperty(key)) {
                    return getters.modelsByType(type)[key];
                }
            }
            return null;
        }
    },
    mutations: {
        add (state, payload) {
            if (!state.models.hasOwnProperty(payload.type)) {
                Vue.set(state.models, payload.type, {});
            }

            Vue.set(state.models[payload.type], payload.key, payload.model);
        },

        setKey(state, payload) {
            if (!state.keys.hasOwnProperty(payload.type)) {
                Vue.set(state.keys, payload.type, {});
            }

            Vue.set(state.keys[payload.type], payload.key, payload.value);
        },

        setTitle(state, payload) {
            if (!state.titles.hasOwnProperty(payload.type)) {
                Vue.set(state.titles, payload.type, {});
            }

            Vue.set(state.titles[payload.type], payload.title, payload.value);
        },

        setRequestStatus (state, payload) {
            Vue.set(state.requestStatuses, payload.type, !!payload.value);
        }
    },
    actions: {
        prepare (context, payload) {
            if (payload.key) {
                if (!context.getters.checkKey(payload.type, payload.key)) {
                    context.commit('setKey', { type: payload.type, key: payload.key, value: false });
                }
            } else if (payload.title) {
                if (!context.getters.checkTitle(payload.type, payload.title)) {
                    context.commit('setTitle', { type: payload.type, title: payload.title, value: null });
                }
            }
        },

        request (context, payload) {
            let requestStatus = context.getters.requestStatusByType(payload.type),
                titles = context.getters.emptyTitlesArrayByType(payload.type),
                keys = context.getters.emptyKeysArrayByType(payload.type);

            if (!requestStatus && (titles.length || keys.length)) {
                let data = new FormData();

                titles.forEach(function (title) {
                    data.append('title[]', title);
                });

                keys.forEach(function (key) {
                    data.append('key[]', key);
                });

                context.commit('setRequestStatus', { type: payload.type, value: true });
                axios.post(payload.apiUrl, data)
                    .then(response => {
                        Object.keys(response.data.models).forEach(function (key) {
                            let model = response.data.models[key];
                            context.commit('add', { type: payload.type, key: key, model: model });
                        });

                        Object.keys(response.data.title).forEach(function (title) {
                            let value = response.data.title[title];
                            context.commit('setTitle', { type: payload.type, title: title, value: value });
                        });

                        Object.keys(response.data.key).forEach(function (key) {
                            let value = response.data.key[key];
                            context.commit('setKey', { type: payload.type, key: key, value: value });
                        });
                    })
                    .finally(() => {
                        context.commit('setRequestStatus', { type: payload.type, value: false });
                    });
            }
        }

    }
};
