import { library } from '@fortawesome/fontawesome-svg-core';

import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faPaw } from '@fortawesome/pro-solid-svg-icons/faPaw';
import { faUserSecret } from '@fortawesome/pro-solid-svg-icons/faUserSecret';
import { faFilm } from '@fortawesome/pro-solid-svg-icons/faFilm';
import { faImage as farFaImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faCheckSquare as farFaCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faIdCard as farFaIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faSortAmountDown } from '@fortawesome/pro-solid-svg-icons/faSortAmountDown';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faFortAwesome as fabFaFortAwesome } from '@fortawesome/free-brands-svg-icons/faFortAwesome';
import { faShield } from '@fortawesome/pro-solid-svg-icons/faShield';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPenSquare } from '@fortawesome/pro-solid-svg-icons/faPenSquare';
import { faCameraRetro } from '@fortawesome/pro-solid-svg-icons/faCameraRetro';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faQuoteRight } from '@fortawesome/pro-solid-svg-icons/faQuoteRight';
import { faUtensilSpoon } from '@fortawesome/pro-solid-svg-icons/faUtensilSpoon';
import { faThermometerFull } from '@fortawesome/pro-solid-svg-icons/faThermometerFull';
import { faBirthdayCake } from '@fortawesome/pro-solid-svg-icons/faBirthdayCake';
import { faCoffee } from '@fortawesome/pro-solid-svg-icons/faCoffee';
import { faAddressBook as farFaAddressBook } from '@fortawesome/pro-regular-svg-icons/faAddressBook';
import { faAddressCard as farFaAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons/faCommentDots';
import { faMoneyBillAlt as farFaMoneyBillAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyBillAlt';
import { faDice as farFaDice } from '@fortawesome/pro-regular-svg-icons/faDice';
import { faBirthdayCake as farFaBirthdayCake } from '@fortawesome/pro-regular-svg-icons/faBirthdayCake';
import { faArchive as farFaArchive } from '@fortawesome/pro-regular-svg-icons/faArchive';
import { faMapMarked as farFaMapMarked } from '@fortawesome/pro-regular-svg-icons/faMapMarked';
import { faFileAlt as farFaFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faEnvelope as farFaEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faRubleSign as farFaRubleSign } from '@fortawesome/pro-regular-svg-icons/faRubleSign';
import { faCreditCard as farFaCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { faYandex as fabFaYandex } from '@fortawesome/free-brands-svg-icons/faYandex';
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faMap } from '@fortawesome/pro-solid-svg-icons/faMap';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons/faCloudDownload';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faPencil as farFaPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faIdCardAlt } from '@fortawesome/pro-solid-svg-icons/faIdCardAlt';
import { faTrash as farFaTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faHandPointRight as farFaHandPointRight } from '@fortawesome/pro-regular-svg-icons/faHandPointRight';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faMars } from '@fortawesome/pro-solid-svg-icons/faMars';
import { faVenus } from '@fortawesome/pro-solid-svg-icons/faVenus';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';
import { faSortAmountUp } from '@fortawesome/pro-solid-svg-icons/faSortAmountUp';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faClock as farFaClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCalendarAlt as farFaCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faEnvira as fabFaEnvira } from '@fortawesome/free-brands-svg-icons/faEnvira';
import { faVideo as farFaVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { faPopcorn as farFaPopcorn } from '@fortawesome/pro-regular-svg-icons/faPopcorn';
import { faFilmAlt as farFaFilmAlt } from '@fortawesome/pro-regular-svg-icons/faFilmAlt';
import { faPie as farFaPie } from '@fortawesome/pro-regular-svg-icons/faPie';
import { faSoup as farFaSoup } from '@fortawesome/pro-regular-svg-icons/faSoup';
import { faSalad as farFaSalad } from '@fortawesome/pro-regular-svg-icons/faSalad';
import { faMugMarshmallows as farFaMugMarshmallows } from '@fortawesome/pro-regular-svg-icons/faMugMarshmallows';
import { faFishCooked as farFaFishCooked } from '@fortawesome/pro-regular-svg-icons/faFishCooked';
import { faHistory as farFaHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faPawAlt as farFaPawAlt } from '@fortawesome/pro-regular-svg-icons/faPawAlt';
import { faFeatherAlt } from '@fortawesome/pro-solid-svg-icons/faFeatherAlt';
import { faBookOpen as farFaBookOpen } from '@fortawesome/pro-regular-svg-icons/faBookOpen';
import { faBooks } from '@fortawesome/pro-solid-svg-icons/faBooks';
import { faSmile as farFaSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faSmileWink as farFaSmileWink } from '@fortawesome/pro-regular-svg-icons/faSmileWink';
import { faScrollOld as farFaScrollOld } from '@fortawesome/pro-regular-svg-icons/faScrollOld';
import { faUndo } from '@fortawesome/pro-solid-svg-icons/faUndo';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faArrowCircleUp as farFaArrowCircleUp } from '@fortawesome/pro-regular-svg-icons/faArrowCircleUp';
import { faDiceD20 as falFaDiceD20 } from '@fortawesome/pro-light-svg-icons/faDiceD20';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faMapSigns } from '@fortawesome/pro-solid-svg-icons/faMapSigns';
import { faInfoCircle as farFaInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faCopy as farFaCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faFilter as farFaFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faFire as farFaFire } from '@fortawesome/pro-regular-svg-icons/faFire';

(function () {

    library.add(faHome, faUsers, faGlobe, faBook, faPaw, faUserSecret, faFilm, farFaImage, faUtensils, faLink, farFaCheckSquare, faAddressBook, faInfoCircle, farFaIdCard, faStar, faCog, faPencil, faBookmark, faSortAmountDown, faUser, faSortAlphaDown, fabFaFortAwesome, faShield, faPlus, faPenSquare, faCameraRetro, faCheck, faQuoteRight, faUtensilSpoon, faThermometerFull, faBirthdayCake, faCoffee, farFaAddressBook, farFaAddressCard, faCommentDots, farFaMoneyBillAlt, farFaDice, farFaBirthdayCake, farFaArchive, farFaMapMarked, farFaFileAlt, faArrowLeft, faArrowRight, faSignOut, faLock, faBars, farFaEnvelope, faSearch, farFaRubleSign, farFaCreditCard, fabFaYandex, faMobileAlt, faClock, faUpload, faMap, faImage, faCloudDownload, faQuoteLeft, farFaPencil, faIdCardAlt, farFaTrash, faCamera, farFaHandPointRight, faTimes, faSpinner, faMars, faVenus, faQuestion, faComments, faSortAmountUp, faTrash, farFaClock, farFaCalendarAlt, fabFaEnvira, farFaVideo, farFaPopcorn, farFaFilmAlt, farFaPie, farFaSoup, farFaSalad, farFaMugMarshmallows, farFaFishCooked, farFaHistory, farFaPawAlt, faFeatherAlt, farFaBookOpen, faBooks, farFaSmile, farFaSmileWink, farFaScrollOld, faUndo, faPaperPlane, farFaArrowCircleUp, falFaDiceD20, faTrashAlt, faCheckCircle, faBell, faMapSigns, farFaInfoCircle, farFaCopy, farFaFilter, faMapMarkerAlt, farFaFire);
    return library;
})();
