import Vue from 'vue'
import VueAxios from 'vue-axios'
import { VueAuthenticate } from 'vue-authenticate'
import axios from 'axios';
import store from "../store";

Vue.use(VueAxios, axios);

const oauthRedirectUri = window.location.origin + '/oauth';
const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
    baseURL: process.env.VUE_APP_SERVER_URL, // Your API domain

    providers: {
        redwall: {
            name: 'redwall',
            url: '/oauth/token',
            clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
            redirectUri: oauthRedirectUri,//'https://oauth.redwall/callback',
            clientSecret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
            oauthType: '2.0',
            popupOptions: { width: 1020, height: 474 },
            authorizationEndpoint: process.env.VUE_APP_SERVER_URL + '/oauth/authorize',
            responseType: 'code',
            responseParams: {
            },
        }
    }
});

export const moduleAuth = {
    namespaced: true,

    state: {
        isAuthenticated: false,
        redirect: null,
        user: {},
        settings: {},
        isInit: false
    },
    getters: {
        getRedirect (state) {
            return state.redirect || process.env.VUE_APP_AFTER_LOGIN_ROUTE;
        },
        isAuth (state) {
            return state.isAuthenticated;
        },
        getUser (state) {
            return state.user;
        },
        getSettings (state) {
            return state.settings;
        },
        /**
         * Полный ключ, с точками
         * @param state
         * @returns {function(*): *}
         */
        setting: (state) => (fullKey) => {
            return fullKey.split('.').reduce((o,i) => o ? o[i] : null, state.settings);
        },
        /**
         * Определение текущего браузера - пока сюда
         * todo - а ведь такое было прямо во vue?
         * @returns {string|*}
         */
        browser: () => (key) => {
            var ua= navigator.userAgent, tem,
                M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE '+(tem[1] || '');
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge?)\/(\d+)/);
                if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
            }
            M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
            if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
            if (key == 'title') {
                return M[0]
            } else if (key == 'version' && M[1]) {
                return M[1];
            } else {
                return M;
            }
        },
        /**
         * Определение того, с мобильного ли устройства пользователь (пока такая версия).
         * @returns {boolean}
         */
        isMobile () {
            return !!navigator.userAgent.match(/iPad|iPhone|Android|BlackBerry|Windows Phone|webOS/i);
        },
        isInit (state) {
            return state.isInit;
        },
        getToken() {
            return vueAuth.getToken()
        }
    },
    mutations: {
        isAuthenticated (state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated
        },
        setRedirect (state, redirect) {
            state.redirect = redirect
        },
        setUser (state, user) {
            state.user = user;
        },
        setSettings (state, settings) {
            state.settings = settings;
        },
        /**
         * * key - краткий ключ! Без точек
         * * objectKey - ключ до объекта, содержащего краткий ключ. Может быть с точками, но должен существовать в объекте! Даже если быть пустым объектом по умолчанию.
         * * value - значение
         * @param state
         * @param payload
         */
        setSetting (state, payload) {
            // Если не было ранее - создаем пустой объект. Пока полагаем так.
            let obj = payload.objectKey ? payload.objectKey.split('.').reduce((o,i) => {
                if (!o[i]) {
                    Vue.set(o, i, {});
                }
                return o[i];
            }, state.settings) : state.settings;
            Vue.set(obj, payload.key, payload.value);
        },
        setInit (state) {
            state.isInit = true;
        }
    },
    actions: {
        // Perform VueAuthenticate login using Vuex actions
        login (context, payload) {
            var user = {
                grant_type: 'authorization_code',
                client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
                redirect_uri: oauthRedirectUri,//'https://oauth.redwall/callback'
            };
            //console.log(user);

            vueAuth.authenticate('redwall', user)/*.login(user, payload.requestOptions)*/
                .then((response) => {
                    //console.log(response);
                    // Если всё ок, инициализируем ещё раз, прежде чем указать приложению, что авторизовано.
                    // А то будет ситуация, когда авторизация есть, а инфы о пользователе нет
                    context.dispatch('loadInit', function () {
                        context.dispatch('initAuth');
                        payload.successCallback();
                    });
                })
                .catch((reason) => {
                    // console.log(reason);
                    // console.log(reason.response);
                    // console.log(reason.response.data);
                    payload.errorCallback();
                });
        },

        logout (context) {
            vueAuth.logout().then(() => {
                context.dispatch('initAuth');
                context.dispatch('loadInit');
            });
        },

        initAuth (context) {
            if (vueAuth.isAuthenticated()) {
                window.authToken = vueAuth.getToken(); // todo - перенести?
            }
            context.commit('isAuthenticated', vueAuth.isAuthenticated());
        },

        putRedirect(context, redirect) {
            context.commit('setRedirect', redirect);
        },

        flushRedirect(context) {
            context.dispatch('putRedirect', null);
        },

        setUser({ dispatch, commit, getters, rootGetters }, payload) {
            if (payload && payload.settings) {
                commit('setSettings', payload.settings);
                delete payload.settings;
            }

            commit('setUser', payload);
            // Если пользователь пуст, считаем, что нет авторизации (может, токен устарел)
            if (!payload) {
                commit('isAuthenticated', false);
            }
        },

        setSetting({ dispatch, commit, getters, rootGetters }, payload) {
            // Прежде всего - если старое значение пусто, то есть вероятность, что настройки пусты.
            // Тогда пропишем их с версией по умолчанию
            if (!getters['setting'](payload.objectKey ? payload.objectKey + '.' + payload.key : payload.key)) {
                // Прописываем тут генерацию значений по умолчанию
                let defaultSettings = {
                    game: null,
                    games: {},
                    sendSetting: 'enter',
                    sendSettingMobile: 'ctrlEnter'
                };
                // Базовые пустые настройки для существующих игр для пользователя
                // Это будет работать только после инициализации игр, но именно тогда и будет первый вызов
                rootGetters['rpg/gamesKeys'].forEach(function (gameKey) {
                    defaultSettings.games[gameKey] = {};
                });

                commit('setSettings', Object.assign(defaultSettings, getters.getSettings));
            }

            commit('setSetting', payload);

            if (getters.isAuth) {
                axios.post('/api/rpg/settings', { settings: JSON.stringify(getters.getSettings) }).then( (response) => {
                    //console.log(response.data);
                }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    dispatch('loading/error', message, { root: true });
                });
            }
        },

        /**
         * Глобальный инит. Пока тут, т.к. чаще всего он с авторизацией.
         * @param dispatch
         * @param commit
         * @param nextCallback
         */
        loadInit({ dispatch, commit }, nextCallback) {
            dispatch('loading/start', 'Инициализация...', { root: true });
            axios.get('/api/rpg/init')
                .then((response) => {
                    //console.log(response.data);
                    // Если в ответ пришел непустой объект, то можно назначать и выбирать персонажей
                    if (response.data && Object.keys(response.data).length) {
                        dispatch('setUser', response.data.user);
                        commit('settings/setSettings', response.data.settings, { root: true });
                        dispatch('rpg/initPreloadedData', response.data.rpg, { root: true });
                        // Устанавливаем признак, что все инициализировано
                        commit('setInit');
                    }

                    dispatch('loading/stop', null, { root: true });
                    if (nextCallback) {
                        nextCallback();
                    }
                })
                .catch((/*reason*/) => {
                    //console.log(reason);
                    dispatch('loading/stop', null, { root: true });
                    dispatch('loading/error', null, { root: true });
                });
        }
    }
};
