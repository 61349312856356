import Vue from 'vue';

export const moduleSettings = {
    namespaced: true,

    state: {
    },
    getters: {
        setting: (state) => (key) => {
            return key.split('.').reduce((o,i) => o ? o[i] : null, state);
        },
    },
    mutations: {
        setSettings (state, payload) {
            Object.keys(payload).forEach(function (setting) {
                Vue.set(state, setting, payload[setting]);
            });
        }
    },
    actions: {
    }
};
