<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon :icon="['far', 'scroll-old']" size="2x"></font-awesome-icon></span>
            <div class="lh-100 flex-grow-1">
                <h4 class="mb-0 redfont lh-100">{{ trans('quest.chronicle') }}</h4>
            </div>
        </div>

        <div class="card-rpg-header my-3 p-3 text-rpg-light bg-rpg-primary rounded box-shadow" v-if="quest">
            <b-media>
                <template v-slot:aside>
                    <b-img :src="quest.imageSmall" blank-color="#654321" width="64" :alt="quest.title"></b-img>
                </template>

                <h5 class="mt-0">{{ quest.title }}</h5>
                <p v-html="quest.desc"/>
            </b-media>
        </div>

        <b-button variant="primary" type="button" class="send-button float-right" v-if="messages.length" @click="showCopy = !showCopy">
          <font-awesome-icon :icon="showCopy ? ['fas', 'comments'] : ['far', 'copy']" fixed-width></font-awesome-icon>
        </b-button>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <b-card class="bg-rpg-panel mb-3" v-if="messages.length">
            <div style="max-height: 50vh; overflow: auto;" v-if="showCopy">
              <textarea style="height: auto; width: 100%;" readonly>{{ copyMessages }}</textarea>
            </div>
            <vue-perfect-scrollbar style="max-height: 50vh; overflow: auto;" v-else>
                <div v-for="(message, index) in messages" class="mb-3" :key="index">
                    <div v-if="isMessageBot(message)" class="bot-message-container">
                        <roll-dice-message class="bot-message" :message="message.message"></roll-dice-message>
                    </div>
                    <b-media v-else>
                        <template v-slot:aside>
                            <b-img :src="messageAuthor(message) ? messageAuthor(message).avatar : serverUrl + setting('defaultAvatar')" blank-color="#654321" width="64" :alt="messageAuthor(message) ? messageAuthor(message).name : null"></b-img>
                        </template>
                        <h5 class="mt-0">{{ messageAuthor(message) ? messageAuthor(message).name : null }}</h5>
                        <p v-html="message.message"/>
                    </b-media>
                </div>
            </vue-perfect-scrollbar>
        </b-card>

        <b-card class="bg-rpg-panel mb-3" v-if="event">
            <b-media>
                <template v-slot:aside>
                    <b-img :src="event.imageSmall" blank-color="#654321" width="64" :alt="quest.title"></b-img>
                </template>

                <h5 class="mt-0">{{ event.title }}</h5>
                <b-form @submit.prevent="setChronicle">
                    <input name="_method" type="hidden" value="PUT">

                    <b-form-group>
                        <basic-ckeditor v-model="chronicle" name="chronicle" :save-function="autoSaveCallback"></basic-ckeditor>
                    </b-form-group>

                    <b-button variant="primary" type="submit" class="send-button float-right" :disabled="!chronicle">
                        <font-awesome-icon :icon="['far', 'scroll-old']" fixed-width></font-awesome-icon> {{ trans('quest.chronicle_send') }}
                    </b-button>
                </b-form>
            </b-media>
        </b-card>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else>
            {{ trans('quest.no_quest') }}
        </div>
    </div>
</template>

<script>
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import BasicCkeditor from "../../components/BasicCkeditor";
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import RollDiceMessage from "../../components/includes/RollDiceMessage";

    export default {
        name: "QuestEventChronicle",
        components: {
            BasicCkeditor,
            FontAwesomeIcon,
            Breadcrumb,
            VuePerfectScrollbar,
            RollDiceMessage
        },
        data() {
            return {
                event: null,
                quest: null,
                messages: [],
                chronicle: '',
                lastSavedChronicle: '',
                showCopy: false
            }
        },
        computed: {
            breadcrumb() {
                return [
                    { route: { name: 'main' }, title: this.trans('title')},
                    { title: this.trans('quest.chronicle')}
                ];
            },

            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            // isMaster () {
            //     return this.$store.getters['rpg/isMaster'];
            // },

            isUserCharacter () {
                return this.$store.getters['rpg/isUserCharacter'];
            },
            autoSaveKey () {
                return 'chronicle-' + this.$route.params.eventKey;
            },
            copyMessages () {
                var copyMessages = '';
                this.messages.forEach(message => {
                  // Пока не считаем броски кубика, но может потребоваться считать другие действия потом
                  if (!this.isMessageBot(message)) {
                    var str = message.message.replace(/(\[[^\]]+\])/g, '');
                    if (str) {
                      copyMessages += (copyMessages ? '\n' : '') + str;
                    }
                  }
                });
                return copyMessages;
            }
        },
        created() {
            this.loadEvent();
        },
        watch: {
            // Не сразу будет доступно, если страница только открылась
            currentCharacter(newCharacter, oldCharacter) {
                this.loadEvent();
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.chronicle = ''; // Обновляем тут, при изменении параметров роута
            this.loadEvent(to.params.questKey, to.params.eventKey);
            next();
        },
        methods: {
            // hasCharacter (key) {
            //     return this.$store.getters['rpg/hasCharacter'](key);
            // },
            loadEvent(questKey = null, eventKey = null) {
                this.event = null;
                this.quest = null;
                //this.lastSavedChronicle = '';
                // Хронику обновляем только при смене маршрута

                if (!this.currentCharacter || this.isUserCharacter) {
                    return;
                }

                questKey = questKey || this.$route.params.questKey;
                eventKey = eventKey || this.$route.params.eventKey;

                this.$store.dispatch('loading/start', 'Загрузка события квеста...', { root: true });

                this.axios.get('/api/rpg/quests/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + questKey + '/' + eventKey + '/chronicle')
                    .then((response) => {
                        if (response.data) {
                            this.quest = response.data.quest;
                            this.event = response.data.event;
                            this.messages = response.data.messages;

                            // Запрашиваем авторов специально для этих сообщений
                            this.fetchAuthors();
                        }

                        // Проверяем автосохранение, заодно блокируем новое сохранение до ответа, что делать со старым
                        if (this.autoSaveKey && localStorage.getItem(this.autoSaveKey) && localStorage.getItem(this.autoSaveKey) !== 'null') {
                            this.lastSavedChronicle = localStorage.getItem(this.autoSaveKey);
                        }

                        // Если путь не менялся, то сохраняем старое. А так - обновляем в роут апдейт.
                        this.chronicle = this.chronicle ? this.chronicle : this.event.desc;

                        // Если есть ранние данные, но нет текущих
                        if (this.lastSavedChronicle && !this.chronicle) {
                            this.chronicle = this.lastSavedChronicle;
                            // Разблокируем автосохранение
                            this.lastSavedChronicle = null;
                        }
                        // Если автосохранение совпадает с текущим, ничего не делаем, а если оно есть и не совпадает, то тогда уже заполняем
                        // Даем пользователю вариант выбрать его
                        else if (this.lastSavedChronicle && this.chronicle !== this.lastSavedChronicle) {
                            this.autoSaveRestore();
                        } else {
                            // Разблокируем его
                            this.lastSavedChronicle = null;
                        }

                        this.$store.dispatch('loading/stop', null, { root: true });
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                    });
            },

            setChronicle: function () {
                let questKey = this.$route.params.questKey,
                    eventKey = this.$route.params.eventKey;

                this.axios.patch('/api/rpg/quests/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + questKey + '/' + eventKey + '/chronicle', {
                    chronicle: this.chronicle
                }).then( (response) => {
                    //this.emptyNewMessage();
                    // Очищаем сохранение, если отправлено и всё ок
                    this.autoSaveCallback('');
                    this.$router.push({ name: 'main' });
                }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                });
            },

            trans (key) {
                return this.$t(key);
            },

            autoSaveCallback(data) {
                return new Promise( resolve => {
                    // Если автосохранение разблокировано
                    if (!this.lastSavedChronicle) {
                        localStorage.setItem(this.autoSaveKey, data);
                    }
                    resolve();
                } )
            },

            autoSaveRestore() {
                const h = this.$createElement;
                // Using HTML string
                const messageVNode = h('div', { domProps: { innerHTML: this.lastSavedChronicle } });

                this.$bvModal.msgBoxConfirm([messageVNode], {
                    title: this.trans('quest.chronicle_autosave'),
                    okTitle: this.trans('quest.restore'),
                    cancelTitle: this.trans('quest.cancel'),
                    centered: true
                }).then(value => {
                    if (value) {
                        this.chronicle = this.lastSavedChronicle;
                    }
                    // Разблокируем автосохранение
                    this.lastSavedChronicle = null;
                }).catch(() => {
                    // Разблокируем автосохранение
                    this.lastSavedChronicle = null;
                })
            },

            /**
             * Автор
             * @param id
             * @param type
             * @returns {*}
             */
            author (id, type) {
                return this.$store.getters['chat/author'](id, type);
            },

            /**
             * Автор сообщения
             * @param message
             * @returns {*}
             */
            messageAuthor (message) {
                return this.author(message.author_id, message.author_type);
            },

            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            isMessageBot: function (message) {
                return message.author_type === 'bot';
            },

            fetchAuthors: function () {
                this.$store.dispatch('chat/fetchAuthors', { messages: this.messages, channel: { type: 'rpg_quest_event', id: this.event.id } });
            },
        }
    }
</script>

<style scoped>

</style>
