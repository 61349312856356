<template>
    <div>
        <multiselect
            v-bind="$props"
            v-model="modelValue"
            :placeholder="placeholder"
            :label="optionLabel"
            :track-by="optionValue"
            :options="optionsArray"
            :multiple="multiple"
            :option-height="optionHeight"
            :show-labels="false"
            :group-values="hasGroups ? groupValues : null"
            :group-label="hasGroups ? groupLabel : null"
            :loading="isLoading"
            :tag-placeholder="tagPlaceholder"
            :taggable="!!addTagUrl"
            :required="required"
            @tag="addTag"
            :class="{ 'is-invalid': state === false }"
        >
            <template slot="singleLabel" slot-scope="props">
                <div v-if="optionImage && props.option[optionImage]" class="multiselect__redimagelabel">
                    <img :src="props.option[optionImage]" :alt="props.option[optionLabel]" class="rounded mr-1">{{ props.option[optionLabel] ? props.option[optionLabel] : props.option.$groupLabel }}
                </div>
                <div v-else-if="optionIcon && props.option[optionIcon]" class="multiselect__rediconlabel">
                    <font-awesome-icon :icon="[props.option[optionIcon].type, props.option[optionIcon].icon]" size="lg" class="mr-1"></font-awesome-icon>{{ props.option[optionLabel] ? props.option[optionLabel] : props.option.$groupLabel }}
                </div>
                <div class="multiselect__redlabel" v-else>
                    {{ props.option[optionLabel] ? props.option[optionLabel] : props.option.$groupLabel }}
                </div>
            </template>
            <template slot="option" slot-scope="props">
                <div v-if="optionImage && props.option[optionImage]" class="multiselect__redimagelabel">
                    <img :src="props.option[optionImage]" :alt="props.option[optionLabel]" class="rounded mr-1">{{ props.option[optionLabel] ? props.option[optionLabel] : props.option.$groupLabel }}
                </div>
                <div v-else-if="optionIcon && props.option[optionIcon]" class="multiselect__rediconlabel">
                    <font-awesome-icon :icon="[props.option[optionIcon].type, props.option[optionIcon].icon]" size="lg" class="mr-1"></font-awesome-icon>{{ props.option[optionLabel] ? props.option[optionLabel] : props.option.$groupLabel }}
                </div>
                <div class="multiselect__group" v-else-if="props.option.$groupLabel">
                    {{ props.option.$groupLabel }}
                </div>
                <div class="multiselect__redlabel" v-else-if="props.option[optionLabel] !== undefined">
                    {{ props.option[optionLabel] }}
                </div>
            </template>
            <span slot="noResult">Ой! Не найдено, хурр, эт самое.</span>
            <span slot="noOptions">Список пуст, эт самое.</span>
        </multiselect>
        <select :multiple="multiple" :name="field" v-model="selectValue" style="display: none" v-if="field">
            <option v-for="option in flatOptionsArray" :value="option[optionValue]">
                {{ option[optionLabel] }}
            </option>
        </select>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    props: {
        ...Multiselect.mixins[0].props,
        ...Multiselect.mixins[1].props,
        ...Multiselect.props, // Прокинуть входные параметры из компонента
        value: { required: true },
        field: { default: null },
        placeholder: { default: null },
        optionHeight: { default: 70 },
        multiple: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        defaultValue: { default: null },
        options: { default: () => [] },
        optionLabel: { default: 'label' }, // Поле объекта массива для отображения
        optionValue: { default: 'value' }, // Поле объекта массива для значения
        optionImage: { default: 'image' },
        optionIcon: { default: 'icon' },
        groupValues: { default: 'values' },
        groupLabel: { default: 'label' },
        nonGroupLabel: { default: '' }, // Название для "виртуальной" группы, содержащей объекты без группы. Сейчас объекты собираются на беке, но важно, чтобы это поле совпадало, чтобы корректно писать теги. Обычно пусто и группа сверху - т.к. пустое скрывается
        tagPlaceholder: { default: 'Добавить новый элемент' },
        addTagUrl: { default: '' }, // Адрес для добавления тега. Контроллер должен быть стандартизирован (как пример - добавление оружия для героя)
        state: { default: null }, // Аналог стейта из bootstrap-vue, пока только для null и false
        onlyValue: { type: Boolean, default: false } // Если задано, то для v-model считает, что там хранятся только значения из value
    },
    components: {
        Multiselect
    },
    name: "RedMultiselect",
    data() {
        return {
            modelValue: null,
            addedOptions: [], // Чтобы можно было добавлять теги
            isLoading: false
        }
    },
    mounted() {
        this.setDefaultValue(this.value ? this.value : this.defaultValue);
    },
    watch: {
        modelValue(val) {
            if (val !== this.value) {
                // пока через опцию
                if (this.onlyValue) {
                    this.$emit('input', this.onlyValueModel);
                } else {
                    this.$emit('input', this.modelValue);
                }
            }
        },
        value(val) {
            if (val !== this.modelValue
                && (
                    (!this.onlyValue && JSON.stringify(val) !== JSON.stringify(this.modelValue))
                    || (this.onlyValue && JSON.stringify(val) !== JSON.stringify(this.valueToOnlyValueModel(this.modelValue)))
                )
            ) {
                // пока через опцию
                if (this.onlyValue) {
                    this.setDefaultValue(this.value);
                } else {
                    this.modelValue = this.value;
                }
            }
        }
    },
    methods: {
        setDefaultValue(initialValue) {
            // todo - уточнить инициализацию для значений с массивами объектов, если будет нужно
            var value;
            if (this.multiple) {
                var defaultValues = Array.isArray(initialValue) ? initialValue : [initialValue];
                value = [];

                defaultValues.forEach(defaultValue => {
                    this.flatOptionsArray.forEach(valueObj => {
                        if (''+valueObj[this.optionValue] === ''+defaultValue) {
                            value.push(valueObj);
                        }
                    });
                });
            } else {
                value = null;
                this.flatOptionsArray.forEach(valueObj => {
                    if (''+valueObj[this.optionValue] === ''+initialValue) {
                        value = valueObj;
                    }
                });
            }
            this.modelValue = value;
        },

        makeOptionsArray(originalOptions, addedOptions, inGroup = false) {
            if (Array.isArray(originalOptions)) {
                return originalOptions;
            } else if (this.options instanceof Object) {
                var options = [];

                if (this.hasGroups && !inGroup) {
                    // Если есть группа - добавляем группу для внегрупповых
                    var nonGroupArray = [];
                }
                Object.keys(originalOptions).forEach(key => {
                    var obj = {};
                    if (originalOptions[key] instanceof Object) {
                        // Собираем группу
                        obj[this.groupLabel] = key;
                        obj[this.groupValues] = this.makeOptionsArray(originalOptions[key], [], true);
                    } else {
                        obj[this.optionValue] = key;
                        obj[this.optionLabel] = originalOptions[key];
                    }

                    if (this.hasGroups && !inGroup && !(originalOptions[key] instanceof Object)) {
                        nonGroupArray.push(obj);
                    } else {
                        options.push(obj);
                    }
                });

                var nonGroupObj;
                if (this.hasGroups && !inGroup && nonGroupArray.length) {
                    nonGroupObj = {};
                    nonGroupObj[this.groupLabel] = this.nonGroupLabel;
                    nonGroupObj[this.groupValues] = nonGroupArray;
                    options.unshift(nonGroupObj);
                }

                // Если есть доп. опции, добавляем их наверх массива "не-группы"
                if (addedOptions.length) {
                    if (this.hasGroups) {
                        // Если был пустой массив не-группы, добавляем туда новые элементы
                        if (nonGroupObj === undefined) {
                            nonGroupObj = {};
                            nonGroupObj[this.groupLabel] = this.nonGroupLabel;
                            nonGroupObj[this.groupValues] = addedOptions;
                            options.unshift(nonGroupObj);
                        } else {
                            // Если не пуст, то он был добавлен в 0-й индекс
                            addedOptions.forEach(obj => {
                                nonGroupObj[this.groupValues].unshift(obj);
                            });

                            Vue.set(options, 0, nonGroupObj);
                        }
                    } else {
                        addedOptions.forEach(obj => {
                            options.unshift(obj);
                        });
                    }
                }

                return options;
            } else {
                var obj = {};
                obj[this.optionValue] = originalOptions;
                obj[this.optionLabel] = originalOptions;
                return [obj];
            }
        },

        addTag(newTag) {
            this.isLoading = true;
            axios.post(this.addTagUrl, {
                tag: newTag
            })
                .then(response => {
                    const tag = response.data.tag;
                    this.addedOptions.push(tag);
                    if (this.multiple) {
                        if (!Array.isArray(this.modelValue)) {
                            this.modelValue = [];
                        }
                        this.modelValue.push(tag);
                    } else {
                        this.modelValue = tag;
                    }
                })
                .catch(error => {
                    console.error(error.response.data.error ? error.response.data.error : error.response.data);
                    if (error.response.data.error) {
                        alert(error.response.data.error === 'The given data was invalid.' ? 'Название некорректно! Возможно, оно уже существует, либо длиннее 191 символа, или короче 2 символов.' : error.response.data.error);
                    }
                })
                .then(() => {
                    this.isLoading = false;
                });
        },

        /**
         * Преобразовывает значение для варианта с отображением только value из объекта.
         * @param value
         * @returns {(*)[]}
         */
        valueToOnlyValueModel(value) {
            if (value) {
                if (value instanceof Array) {
                    value = value.map(elem => {
                        return elem instanceof Object ? elem[this.optionValue] : elem;
                    });
                } else {
                    value = value instanceof Object ? value[this.optionValue] : value;
                }
            }
            return value;
        }
    },
    computed: {
        optionsArray() {
            return this.makeOptionsArray(this.options, this.addedOptions)
        },
        flatOptionsArray() {
            var options = [];
            this.optionsArray.forEach(obj => {
                if (Array.isArray(obj[this.groupValues])) {
                    obj[this.groupValues].forEach(innerObj => {
                        options.push(innerObj);
                    })
                } else {
                    options.push(obj);
                }
            });
            if (this.addedOptions.length) {
                this.addedOptions.forEach(innerObj => {
                    options.push(innerObj);
                })
            }
            return options;
        },
        hasGroups() {
            var has = false;
            if (Array.isArray(this.options)) {
                this.options.forEach(obj => {
                    if (obj instanceof Object && obj[this.groupValues]) {
                        has = true;
                    }
                });
            } else if (this.options && this.options instanceof Object) {
                Object.keys(this.options).forEach(key => {
                    if (this.options[key] instanceof Object) {
                        has = true;
                    }
                });
            }
            return has;
        },
        selectValue() {
            if (this.multiple) {
                if (this.modelValue) {
                    var values = [];
                    this.modelValue.forEach(obj => {
                        values.push(obj[this.optionValue]);
                    });
                    return values;
                } else {
                    return [];
                }
            } else {
                return this.modelValue ? this.modelValue[this.optionValue] : null;
            }
        },
        onlyValueModel() {
            return this.valueToOnlyValueModel(this.modelValue);
        }
    }
}
</script>

<style lang="scss">
.is-invalid {
    .multiselect__tags {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .multiselect__tags {
        background-position: right calc(0.375em + 0.1875rem + 1.5rem) center;
    }
}
</style>
