import Vue from 'vue'

export const moduleLoading = {
    namespaced: true,

    state: {
        loading: false,
        loadingMessage: '',
        error: false,
        errorMessage: '',
        loadingCount: 0
    },
    getters: {
        isLoading (state) {
            return state.loading;
        },
        getLoadingMessage (state) {
            return state.loadingMessage || 'Загрузка...';
        },
        isError (state) {
            return state.error;
        },
        getErrorMessage (state) {
            return state.errorMessage || 'Ошибка при загрузке. Попробуйте заново.';
        }
    },
    mutations: {
        /**
         * Теперь учитывает, сколько раз запущено, и не прекращает загрузку, пока все загружаемые в текущий момент не завершатся.
         * @param state
         * @param payload
         */
        loading (state, payload) {
            if (payload.loading) {
                state.loadingCount++;
                if (!state.loading) {
                    state.loading = true;
                }
            } else {
                if (state.loadingCount > 0) {
                    state.loadingCount--;
                } else {
                    state.loadingCount = 0;
                }

                if (state.loadingCount === 0) {
                    state.loading = false;
                }
            }
        },
        setLoadingMessage (state, payload) {
            state.loadingMessage = payload.loadingMessage
        },
        error (state, payload) {
            state.error = payload.error
        },
        setErrorMessage (state, payload) {
            state.errorMessage = payload.errorMessage
        }
    },
    actions: {
        start (context, message) {
            if (message) {
                context.commit('setLoadingMessage', {
                    loadingMessage: message
                });
            }

            context.commit('loading', {
                loading: true
            });
        },

        stop (context) {
            context.commit('loading', {
                loading: false
            });

            context.commit('setLoadingMessage', {
                loadingMessage: ''
            });
        },

        error (context, message) {
            // Новая версия - message, toast
            context.dispatch('message/error', message, { root: true });
            
            // if (message) {
            //     context.commit('setErrorMessage', {
            //         errorMessage: message
            //     });
            // }
            //
            // context.commit('error', {
            //     error: true
            // });
        },

        hideError (context) {
            context.commit('error', {
                error: false
            });

            context.commit('setErrorMessage', {
                errorMessage: ''
            });
        },

    }
};
