import Vue from 'vue';
import axios from 'axios';

export const moduleRpg = {
    namespaced: true,

    state: {
        games: {},
        game: null,
        characters: {},
        character: null,
        bgimage: null,
        isInit: false,
        i18n: null,
        locations: {},
        locationsSet: false,
        isCharactersLoaded: false,
        online: [],
        needOnlineDate: null,
        needOnlineInterval: 60000
    },
    getters: {
        isMaster (state) {
            return state.character && state.character === 'master';
        },
        isUserCharacter (state) {
            return state.character && state.character === 'user';
        },
        currentGame (state) {
            return state.game && state.games && state.games.hasOwnProperty(state.game)
                ? state.games[state.game]
                : null;
        },
        getGame: (state) => (key) => {
            return state.games && state.games.hasOwnProperty(key)
                ? state.games[key]
                : null;
        },
        games (state) {
            return state.games ? state.games : {};
        },
        gamesKeys (state) {
            return state.games ? Object.keys(state.games) : [];
        },
        charactersKeys (state) {
            return state.characters ? Object.keys(state.characters) : [];
        },
        getCharacter: (state) => (key) => {
            return state.characters && state.characters.hasOwnProperty(key)
                ? state.characters[key]
                : null;
        },
        currentCharacter (state) {
            return state.character && state.characters && state.characters.hasOwnProperty(state.character)
                ? state.characters[state.character]
                : null;
        },
        /**
         * Является ли персонаж с данным ключом текущим персонажем пользователя
         * @param state
         * @returns {function(*): (*|null|boolean)}
         */
        isCharacter: (state) => (key) => {
            return state.character && state.character.toString() === key.toString();
        },
        /**
         * Существует ли у пользователя персонаж с данным ключом
         * @param state
         * @returns {function(*=): boolean}
         */
        hasCharacter: (state) => (key) => {
            return Object.keys(state.characters).indexOf(key.toString()) !== -1;
        },

        background (state) {
            return state.bgimage;
        },

        /**
         * Адрес сервера
         * @returns {string}
         */
        serverUrl () {
            return process.env.VUE_APP_SERVER_URL;
        },
        isInit (state) {
            return state.isInit;
        },
        trans: (state) => (key) => {
            return state.i18n.t(key);
        },

        /**
         * Общая информация о локации по ключу
         * @param state
         * @returns {function(*): boolean}
         */
        location: (state) => (key) => {
            return state.locations[key];
        },

        /**
         * Все локации игры
         * @returns {*}
         */
        locations (state) {
            return state.locations;
        },

        /**
         * Признак того, что локации записаны (и можно их использовать)
         * @param state
         * @returns {boolean}
         */
        locationsSet (state) {
            return state.locationsSet;
        },

        /**
         * Получить настройку для текущей игры по ключу
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @returns {function(*): (*|null)}
         */
        gameSetting: (state, getters, rootState, rootGetters) => (settingKey) => {
            // Это всё актуально только если игра есть
            if (getters.currentGame) {
                return rootGetters['auth/setting']('games.' + getters.currentGame.key + '.' + settingKey);
            }
            return null;
        },

        isCharactersLoaded (state) {
            return state.isCharactersLoaded;
        },

        online (state) {
            return state.online;
        },

        /**
         * Признак того, что нужно обновить онлайн.
         * Текущее время - из-за кеширования (не реактивно из Date.now()).
         * @param state
         * @returns {function(*): *}
         */
        needOnline: (state) => (currentTime) => {
            return !state.needOnlineDate || (currentTime - state.needOnlineDate > state.needOnlineInterval);
        },

        isOnline: (state) => (id, type) => {
            return state.online.findIndex(char => +char.id === +id && char.type === type) !== -1;
        },
    },
    mutations: {
        setGames (state, payload) {
            state.games = payload;
        },
        setGame (state, gameKey) {
            state.game = gameKey;
        },
        setCharacters (state, payload) {
            state.characters = payload;
        },
        setCharactersLoaded (state, payload) {
            state.isCharactersLoaded = payload;
        },
        setCharacter (state, characterKey) {
            state.character = characterKey;
        },
        setBgimage (state, bgimage) {
            state.bgimage = bgimage;
        },
        setInit (state, isInit = true) {
            state.isInit = isInit;
        },
        setI18n (state, i18n) {
            state.i18n = i18n;
        },
        setCharacterData (state, characterData) {
            Vue.set(state.characters, characterData.key, characterData);
        },
        setLocations (state, payload) {
            if (Array.isArray(payload)) {
                state.locations = {};
                payload.forEach(location => {
                    Vue.set(state.locations, location.key, location);
                });
            } else {
                state.locations = payload;
            }

            state.locationsSet = true;
        },
        unsetLocations (state) {
            state.locations = {};
            state.locationsSet = false;
        },
        setOnline (state, payload) {
            if (payload instanceof Object) {
                payload = Object.values(payload);
            }
            state.online = payload;
        },
        /**
         * Установить дату обработки запроса на загрузку.
         * Если не передавать, загрузится в следующую же секунду.
         * @param state
         * @param payload
         */
        setNeedOnlineDate (state, payload = null) {
            state.needOnlineDate = payload;
        },
    },
    actions: {

        /**
         * Пока не грузит из сети, но на основе данных с сервера
         * @param context
         * @param payload
         */
        initPreloadedData({ dispatch, commit, getters, rootGetters }, payload) {
            // Обнуляем персонажей и устанавливаем признак того, что игра ещё не инициализирована
            commit('setInit', false);
            commit('setCharacters', {});
            commit('setCharacter', null);

            // Запускаем цикл для загрузки онлайна. Первый раз онлайн будет не в нем, а при выборе игры.
            // При выборе новой игры будет внеочередной запрос для нового списка
            dispatch('processOnline');

            // Устанавливаем признак игры
            commit('setGames', payload.games);

            // Устанавливаем игру (ключ пуст, поэтому берется из настроек или первая доступная)
            //dispatch('setGame');
            // Переносим в роутер
        },

        /**
         * Устанавливаем игру (по ключу или из настроек).
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         * @param gameKey
         */
        setGame({ dispatch, commit, getters, rootGetters }, gameKey = null) {
            // Это всё актуально только если игры есть
            if (getters.games && getters.gamesKeys.length) {
                // Смотрим, что в настройках
                let authSettingGame = rootGetters['auth/setting']('game');
                // Текущая игра - на основе настроек пользователя, если нет ключа
                if (!gameKey) {
                    gameKey = authSettingGame;
                }

                // Если среди игр нет такого ключа - выбираем первую из них
                if (!gameKey || !getters.games.hasOwnProperty(gameKey)) {
                    gameKey = getters.gamesKeys[0];
                }

                // Если текущая игра не установлена или другая - то ставим игру
                if (!getters.isInit || !getters.currentGame || getters.currentGame.key !== gameKey) {
                    // Ставим инициализацию (нужно, чтобы можно было сюда попасть после входа в приложение)
                    commit('setInit', true);
                    // Веротно, закрыть чат лучше и тут, в момент смены игры, пусть дважды, но лучше перестраховаться
                    dispatch('chat/freshChat', null, { root: true });
                    commit('setGame', gameKey);

                    // Если игра в настройках отличается, пишем туда
                    if (gameKey !== authSettingGame) {
                        dispatch('auth/setSetting', { key: 'game', value: gameKey}, { root: true });
                    }

                    // Обнулить персонажей и загрузить персонажей для новой игры,
                    // потом выбрать там нужного
                    dispatch('loadCharacters');

                    // Пока грузим тут все локации из игры - основную инфу о них. Подробности будем грузить при необходимости
                    dispatch('loadLocations');
                }
            }
        },

        /**
         * Устанавливаем персонажа (по ключу или из настроек).
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         * @param characterKey
         */
        setCharacter({ dispatch, commit, getters, rootGetters }, characterKey = null) {
            // Это всё актуально только если игры и персонажи есть
            if (getters.currentGame && getters.charactersKeys && getters.charactersKeys.length) {
                // Смотрим, что в настройках
                let authSettingCharacter = getters.gameSetting('character');
                // Текущая игра - на основе настроек пользователя, если нет ключа
                if (!characterKey) {
                    characterKey = authSettingCharacter;
                }

                // Если среди игр нет такого ключа - выбираем первую из них
                if (!characterKey || !getters.hasCharacter(characterKey)) {
                    characterKey = getters.charactersKeys[0];
                }

                // Если текущий персонаж не установлен - ставим его
                // Ставим всегда, т.к. тут может вызываться после выбора другой игры, и может тупить иначе.
                //if (!getters.currentCharacter || getters.currentCharacter.key !== characterKey) {
                // Веротно, закрыть чат лучше тут, в момент смены персонажа
                dispatch('chat/freshChat', null, { root: true });

                commit('setCharacter', characterKey);

                // Загрузить список онлайн для текущей игры и персонажа
                dispatch('loadOnline');

                // Если игра в настройках отличается, пишем туда
                if (characterKey !== authSettingCharacter) {
                    dispatch('setGameSettings', { character: characterKey });
                }

                // Если в настройках чат включен, пробуем инициализировать его
                if (getters.currentGame.chat) {
                    dispatch('chat/initChat', null, { root: true });
                }
                //}
            }
        },

        loadCharacters({ dispatch, commit, getters, rootGetters }) {
            commit('setCharacters', []);
            // Пишем признак того, что мы не загрузили персонажей
            commit('setCharactersLoaded', false);

            if (getters.currentGame) {
                if (rootGetters['auth/isAuth']) {
                    dispatch('loading/start', 'Загрузка информации о персонажах...', { root: true });

                    axios.get('/api/rpg/characters/' + getters.currentGame.key)
                        .then((response) => {
                            // Если в ответ пришел непустой объект, то можно назначать и выбирать персонажей
                            if (response.data && Object.keys(response.data).length) {
                                commit('setCharacters', response.data);
                                // Устанавливаем персонажа - по ключу из настроек или первого доступного
                                dispatch('setCharacter');
                            }

                            // Пишем признак того, что мы загрузили персонажей
                            commit('setCharactersLoaded', true);

                            dispatch('loading/stop', null, { root: true });
                        })
                        .catch((/*reason*/) => {
                            //console.log(reason);
                            dispatch('loading/stop', null, { root: true });
                            dispatch('loading/error', null, { root: true });
                        });
                } else {
                    // Пишем признак того, что мы загрузили персонажей
                    commit('setCharactersLoaded', true);
                }
            }
        },

        setBackground({ dispatch, commit, getters, rootGetters }, image = null) {
            commit('setBgimage', image);
        },

        /**
         * Прописывает новую информацию о персонаже - после редактирования или смены аватара.
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         * @param characterData
         */
        setCharacterData({ dispatch, commit, getters, rootGetters }, characterData) {
            commit('setCharacterData', characterData);
        },

        /**
         * Загрузка информации о локациях игры
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         */
        loadLocations({ dispatch, commit, getters, rootGetters }) {
            commit('unsetLocations', {});

            if (getters.currentGame) {
                dispatch('loading/start', 'Загрузка информации о локациях...', { root: true });

                axios.get('/api/rpg/locations/' + getters.currentGame.key)
                    .then((response) => {
                        // Если в ответ пришел непустой объект, то можно назначать и выбирать персонажей
                        if (response.data && Object.keys(response.data).length) {
                            commit('setLocations', response.data);
                        }

                        dispatch('loading/stop', null, { root: true });
                    })
                    .catch((/*reason*/) => {
                        //console.log(reason);
                        dispatch('loading/stop', null, { root: true });
                        dispatch('loading/error', null, { root: true });
                    });
            }
        },

        /**
         * Сменить локацию для текущего персонажа
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         * @param locationKey
         */
        moveCurrentCharacter ({ dispatch, commit, getters, rootGetters }, locationKey) {
            commit('setCharacterData', Object.assign({}, getters.currentCharacter, { location: locationKey }));
        },

        /**
         * Записывает настройки для текущей игры.
         * Пишутся только те, что присутствуют в объекте, по одной - остальные не меняются.
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         * @param payload
         */
        setGameSettings({ dispatch, commit, getters, rootGetters }, payload) {
            // Это всё актуально только если игра есть
            if (getters.currentGame) {
                // Пишем в настройки для каждого элемента вида ключ => значение
                Object.keys(payload).forEach((settingKey) => {
                    dispatch('auth/setSetting', { key: settingKey, value: payload[settingKey], objectKey: 'games.' + getters.currentGame.key}, { root: true });
                });
            }
        },

        /**
         * Загрузка информации об игроках онлайн для текущий игры
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         */
        loadOnline({ dispatch, commit, getters, rootGetters }) {
            if (getters.currentGame) {
                commit('setNeedOnlineDate', Date.now());
                axios.post('/api/rpg/online/' + getters.currentGame.key + (getters.currentCharacter ? '/' + getters.currentCharacter.key : ''), {
                    channels: rootGetters['chat/simpleOpenedChannels']
                })
                    .then((response) => {
                        commit('setOnline', response.data);
                    })
                    .catch((/*reason*/) => {
                        dispatch('loading/error', null, { root: true });
                    });
            }
        },

        /**
         * Запуск проверки онлайна раз в минуту
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         */
        processOnline({ dispatch, commit, getters, rootGetters }) {
            if (getters.isInit && getters.needOnline(Date.now())) {
                dispatch('loadOnline');
            }
            setTimeout(() => dispatch('processOnline'), 1000);
        },

        /**
         * Обнуляет время последнего запроса,
         * чтобы в следующую же секунду был запрошен список онлайн.
         * Обычно нужно после получения сообщения.
         * @param commit
         * @param getters
         */
        getOnlineNow: function ({ commit, getters }) {
            if (getters.isInit) {
                commit('setNeedOnlineDate');
            }
        }
    }
};
