import store from './store';
import Vue from 'vue';
import Router from 'vue-router';
import Main from './views/Main.vue';
import Profiles from './views/Profiles/Profiles';
import ProfilesShow from './views/Profiles/ProfilesShow';
import ProfilesList from './views/Profiles/ProfilesList';
import ProfilesChoose from "./views/Profiles/ProfilesChoose";
import Game from "./views/Game";
import Index from "./views/Index";
import Chat from "./views/Chat/Chat";
import Channels from "./views/Chat/Channels";
import Channel from "./views/Chat/Channel";
import Profile from "./views/Profile/Profile";
import ProfileIndex from "./views/Profile/ProfileIndex";
import Rules from './views/Rules';
import Quest from "./views/Quest/Quest";
import QuestEventChronicle from "./views/Quest/QuestEventChronicle";
import LocationMap from "./views/Locations/LocationMap";
import LocationList from "./views/Locations/LocationList";
import LocationShow from "./views/Locations/LocationShow";
import axios from "axios";
import Login from "./views/Login";
import ProfileEdit from "./views/Profile/ProfileEdit";
import ProfileCreate from "./views/Profile/ProfileCreate";

Vue.use(Router);

// https://router.vuejs.org/ru/
// Управление страницами (в адресе)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '',
            name: 'index',
            component: Index,
            //meta: { requiresLogin: true }
        },
        {
            path: '/:game',
            component: Game,
            // beforeEnter: (to, from, next) => {
            //     console.log(to.params.game);
            //     //dispatch('setGame');
            //     store.dispatch('rpg/setGame', to.params.game);
            //     //store.getters('rpg/currengGame');
            //     console.log(store.getters['rpg/currentGame']);
            //     console.log(store.getters['rpg/games']);
            //     next();
            // },
            children: [
                {
                    path: '',
                    name: 'main',
                    component: Main,
                    //meta: { requiresLogin: true }
                },
                {
                    path: 'rules',
                    name: 'rules',
                    component: Rules,
                    //meta: { requiresLogin: true }
                },
                {
                    path: 'profiles/',
                    component: Profiles,
                    children: [
                        {
                            path: 'choose',
                            name: 'profiles.choose',
                            component: ProfilesChoose
                        },
                        {
                            path: '',
                            name: 'profiles.index',
                            component: ProfilesList
                        },
                        {
                            path: ':id-:seo',
                            name: 'profiles.show',
                            component: ProfilesShow
                        }
                    ]
                },
                {
                    path: 'profile/',
                    component: Profile,
                    children: [
                        {
                            path: '',
                            name: 'profile.index',
                            component: ProfileIndex
                        },
                        {
                            path: 'edit',
                            name: 'profile.edit',
                            component: ProfileEdit
                        },
                        {
                            path: 'create',
                            name: 'profile.create',
                            component: ProfileCreate
                        },
                    ]
                },
                {
                    path: 'chat/',
                    component: Chat,
                    children: [
                        {
                            path: '',
                            name: 'chat',
                            component: Channels
                        },
                        {
                            path: ':channelType/:channelId',
                            name: 'channel',
                            component: Channel
                        }
                    ]
                },
                {
                    path: 'quests/',
                    component: Quest,
                    children: [
                        {
                            path: ':questKey/:eventKey/chronicle',
                            name: 'quest.chronicle',
                            component: QuestEventChronicle
                        }
                    ]
                },
                {
                    path: 'map/:currentMapKey?',
                    name: 'map',
                    component: LocationMap
                },
                {
                    path: 'locations/:currentMapKey?',
                    name: 'locations',
                    component: LocationList
                },
                {
                    path: 'location/:currentLocationKey/:all?',
                    name: 'location',
                    component: LocationShow
                },
            ]
        },
    ]
});

// // Инициируем хранилище аутентификации - если в прошлое обновление был вход
store.dispatch('auth/initAuth');

router.beforeEach((to, from, next) => {
    // Чтобы записывался всегда, если не залогинен
    if (!store.getters['auth/isAuthenticated'] && to.name !== 'login') {
        store.dispatch('auth/putRedirect', to);
    }

    if (to.matched.some(record => record.meta.requiresLogin) && !store.getters['auth/isAuthenticated']) {
        //next("/login")
        store.dispatch('auth/login', {
            user: {},
            successCallback: function () {
                //this.showMessage('success', 'Успешный вход!');
                //console.log('Успешный вход!');
                //var redirect = this.redirect;
                //store.dispatch('auth/flushRedirect');
                //router.push(redirect)
            },
            errorCallback: function () {
                //this.showMessage('danger', 'Ошибка! Возможно, вы ввели неверные данные.');
                //console.log('Ошибка! Возможно, вы ввели неверные данные.');
                store.dispatch('loading/error', 'Ошибка! Возможно, вы ввели неверные данные.');
            }
        });
    } else if (!store.getters['auth/isInit']) {
        store.dispatch('auth/loadInit', next);
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    // Перед сменой роута - сбрасываем фон
    store.dispatch('rpg/setBackground');
    next();
});

// Записывает роутер в стор - для возможности обращения
//store.commit('setRouter', router);

export default router;
