<template>
    <div>
        <div class="card-rpg-header my-3 p-3 text-rpg-light bg-rpg-primary rounded box-shadow" v-if="map">
            <div class="media d-block d-md-flex">
                <img class="d-flex mb-3 mx-auto rounded" :src="map.image" :alt="map.title">
                <div class="media-body ml-md-3 ml-0">
                    <h5 class="mt-0 text-center text-md-left redfont">{{ map.title }}</h5>
                    <span class="turn-content" v-html="map.desc" v-if="map.desc"/>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <breadcrumb :items="breadcrumb"></breadcrumb>
            <b-form-radio-group button-variant="outline-primary" name="radio-btn-outline" buttons @change="goToMapRoute()" v-model="mapVariant">
                <b-form-radio value="map"><font-awesome-icon icon="map" fixed-width></font-awesome-icon> {{ $t('map.title') }}</b-form-radio>
                <b-form-radio value="locations" @click.prevent=""><font-awesome-icon icon="bars" fixed-width></font-awesome-icon> {{ $t('location.title') }}</b-form-radio>
            </b-form-radio-group>
        </div>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow">
            <map-show :map-key="mapKey" @loadMapInfo="map = $event" v-if="displayMap" @nomap="gotToLocations()"></map-show>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../../components/includes/Breadcrumb";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import MapShow from "../../components/MapShow";

export default {
    name: "LocationMap",
    components: {
        MapShow,
        FontAwesomeIcon,
        Breadcrumb
    },
    data() {
        return {
            mapKey: null,
            displayMap: false,
            map: null,
            mapVariant: 'map'
        }
    },
    computed: {
        breadcrumb() {
            let breadcrumb = [
                { route: { name: 'main' }, title: this.$t('title')}
            ];

            if (this.map) {
                if (this.map.breadcrumb) {
                    this.map.breadcrumb.forEach(function (bread) {
                        breadcrumb.push({ route: { name: 'map', params: { currentMapKey: bread.key } }, title: bread.title });
                    })
                }
            }
            breadcrumb.push({ title: this.map ? this.map.title : this.$t('map.title')});

            return breadcrumb;
        },

        currentGame () {
            return this.$store.getters['rpg/currentGame'];
        },

        currentCharacter () {
            return this.$store.getters['rpg/currentCharacter'];
        },

        isMaster () {
            return this.$store.getters['rpg/isMaster'];
        },
    },
    created() {
        this.initMap(this.$route.params.currentMapKey);
    },
    watch: {
        currentGame(newGame, oldGame) {
            this.initMap(this.$route.params.currentMapKey);
        },
    },
    beforeRouteUpdate (to, from, next) {
        // обрабатываем изменение параметров маршрута...
        // не забываем вызвать next()
        this.initMap(to.params.currentMapKey);
        next();
    },
    methods: {
        hasCharacter (key) {
            return this.$store.getters['rpg/hasCharacter'](key);
        },
        initMap(currentMapKey = null) {
            // Если опция "отображать только список локаций", то сразу направляем туда
            if (this.currentGame.is_map_list) {
                this.gotToLocations(currentMapKey);
            }

            this.mapKey = currentMapKey;
            this.displayMap = false;
            // Делаем небольшую задержку отображения для сброса старого скрипта, если он был
            setTimeout(() => this.displayMap = true, 200);
        },

        gotToLocations(currentMapKey = null) {
            this.$router.push({ name: 'locations', params: { currentMapKey: currentMapKey ? currentMapKey : this.mapKey } });
        },

        goToMapRoute () {
            this.$router.push({ ...this.$route, params: { ...this.$route.params }, name: this.mapVariant });
        },
    }
}
</script>

<style scoped>

</style>
