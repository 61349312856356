<template>
    <div>
        <div class="card-rpg-header my-3 p-3 text-rpg-light bg-rpg-primary rounded box-shadow" v-if="map">
            <div class="media d-block d-md-flex">
                <img class="d-flex mb-3 mx-auto rounded" :src="map.image" :alt="map.title">
                <div class="media-body ml-md-3 ml-0">
                    <h5 class="mt-0 text-center text-md-left redfont">{{ map.title }}</h5>
                    <span class="turn-content" v-html="map.desc" v-if="map.desc"/>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <breadcrumb :items="breadcrumb"></breadcrumb>
            <b-form-radio-group button-variant="outline-primary" name="radio-btn-outline" buttons v-if="!currentGame.is_map_list" @change="goToMapRoute()" v-model="mapVariant">
                <b-form-radio value="map"><font-awesome-icon icon="map" fixed-width></font-awesome-icon> {{ $t('map.title') }}</b-form-radio>
                <b-form-radio value="locations" @click.prevent=""><font-awesome-icon icon="bars" fixed-width></font-awesome-icon> {{ $t('location.title') }}</b-form-radio>
            </b-form-radio-group>
        </div>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="(locations && locations.length) || (maps && maps.length)">
            <b-card class="border-primary" style="margin: -0.5rem;" no-body v-if="maps.length">
                <b-card-body style="margin: -0.75rem;">
                    <div class="media text-muted pt-3" v-for="mapItem in maps">
                        <img class="mr-2 rounded" :src="mapItem.image" :alt="mapItem.title">
                        <div class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <strong class="text-rpg-primary">
                                    <router-link  :to="{ name: 'map', params: { currentMapKey: mapItem.key } }">
                                        {{ mapItem.title }}
                                    </router-link>
                                </strong>
                            </div>
                        </div>

                        <div v-if="mapItem.lastTurn" class="media col-md-3">
                            <img class="mr-2 rounded" :src="mapItem.lastTurn.char.smallImg" :alt="mapItem.lastTurn.char.name" v-if="!mapItem.lastTurn.charIsMaster && mapItem.lastTurn.char">
                            <div class="media-body mb-0 small lh-125">
                                <div class="d-flex justify-content-between align-items-center w-100 pb-3 border-bottom border-gray">
                                    <strong class="text-rpg-primary">
                                        <span v-if="mapItem.lastTurn.charIsMaster">{{ trans('master.comment_name') }}</span>
                                        <router-link :to="{ name: 'profiles.show', params: { id: mapItem.lastTurn.char.id, seo: mapItem.lastTurn.char.seo } }" v-else-if="mapItem.lastTurn.char && currentGame.profile_show">
                                            {{ mapItem.lastTurn.char.name }}
                                        </router-link>
                                        <span v-else-if="mapItem.lastTurn.char">{{ mapItem.lastTurn.char.name }}</span>
                                    </strong>
                                </div>
                                <div>{{ mapItem.lastTurn.created_at }}</div>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-card>

            <div class="media text-muted pt-3" v-for="location in locations" v-if="locations && locations.length">
                <img class="mr-2 rounded" :src="location.image" :alt="location.title">
                <div class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <strong class="text-rpg-primary">
                            <router-link  :to="{ name: 'location', params: { currentLocationKey: location.key } }">
                                {{ location.title }}
                            </router-link>
                        </strong>
                    </div>
                </div>
                <div v-if="location.lastTurn" class="media ml-2 col-md-3">
                  <b-avatar class="mr-2" rounded size="4rem" :src="location.lastTurn.char.smallImg" :alt="location.lastTurn.char.name" v-if="!location.lastTurn.charIsMaster && location.lastTurn.char"></b-avatar>
                  <div class="media-body mb-0 small lh-125">
                    <div class="d-flex justify-content-between align-items-center w-100 pb-3 border-bottom border-gray">
                      <strong class="text-rpg-primary">
                        <span v-if="location.lastTurn.charIsMaster">{{ trans('master.comment_name') }}</span>
                        <router-link :to="{ name: 'profiles.show', params: { id: location.lastTurn.char.id, seo: location.lastTurn.char.seo } }" v-else-if="location.lastTurn.char && currentGame.profile_show">
                          {{ location.lastTurn.char.name }}
                        </router-link>
                        <span v-else-if="location.lastTurn.char">{{ location.lastTurn.char.name }}</span>
                      </strong>
                    </div>
                    <div>{{ location.lastTurn.created_at }}</div>
                  </div>
                </div>
            </div>

            <span v-if="!locations || !locations.length">
                {{ trans('location.no_locations') }}
            </span>

            <div class="row justify-content-center" v-if="locations && locations.length && lastPage > 1">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="lastPage"
                        use-router
                        hide-goto-end-buttons
                >
                    <template v-slot:prev-text><font-awesome-icon icon="arrow-left"><i class="fas fa-arrow-left"></i></font-awesome-icon></template>
                    <template v-slot:next-text><font-awesome-icon icon="arrow-right"><i class="fas fa-arrow-right"></i></font-awesome-icon></template>
                </b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: "LocationList",
        components: {
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                map: null,
                maps: [],
                locations: [],
                lastPage: 0,
                mapVariant: 'locations'
            }
        },
        computed: {
            breadcrumb() {
                let breadcrumb = [
                    { route: { name: 'main' }, title: this.trans('title')}
                ];

                if (this.map) {
                    if (this.map.breadcrumb) {
                        this.map.breadcrumb.forEach(function (bread) {
                            breadcrumb.push({ route: { name: 'map', params: { currentMapKey: bread.key } }, title: bread.title });
                        })
                    }
                }
                breadcrumb.push({ title: this.map ? this.map.title : this.trans('map.title')});

                return breadcrumb;
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
        },
        created() {
            this.loadMap(this.$route.query && this.$route.query.page ? this.$route.query.page : 1, this.$route.params.currentMapKey);
        },
        watch: {
            currentGame(newGame, oldGame) {
                this.loadMap(this.$route.query && this.$route.query.page ? this.$route.query.page : 1, this.$route.params.currentMapKey);
            },
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.loadMap(to.query && to.query.page ? to.query.page : 1, to.params.currentMapKey);
            next();
        },
        methods: {
            hasCharacter (key) {
                return this.$store.getters['rpg/hasCharacter'](key);
            },
            loadMap(pageNum, currentMapKey = null) {
                this.map = null;
                this.locations = [];
                this.maps = [];
                this.$store.dispatch('loading/start', 'Загрузка локаций...', { root: true });

                this.axios.get('/api/rpg/map/locations/' + this.currentGame.key + (currentMapKey ? '/' + currentMapKey : '') + this.linkGen(pageNum))
                    .then((response) => {
                        this.map = response.data.map;
                        this.maps = response.data.maps;
                        this.locations = response.data.locations.data;
                        // Иногда приходит не массив, а объект?
                        if (this.locations instanceof Object) {
                            this.locations = Object.values(this.locations);
                        }
                        this.lastPage = response.data.locations.last_page;
                        this.$store.dispatch('loading/stop', null, { root: true });

                        if (this.map.bgimage) {
                            this.$store.dispatch('rpg/setBackground', this.map.bgimage);
                        }
                    }).catch((error) => {
                        let message = error && error.response ? (error.response.data.error ? error.response.data.error : error.response.data) : error;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                });
            },
            linkGen(pageNum) {
                return pageNum === 1 ? '?' : `?page=${pageNum}`
            },
            trans (key) {
                return this.$t(key);
            },

            goToMapRoute () {
                this.$router.push({ ...this.$route, params: { ...this.$route.params }, name: this.mapVariant });
            },
        }
    }
</script>

<style scoped>

</style>
