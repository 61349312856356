<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon icon="users" size="2x"></font-awesome-icon></span>
            <div class="lh-100">
                <h4 class="mb-0 redfont lh-100">{{ trans('profiles.title') }}</h4>
            </div>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <transition name="list" mode="out-in">
            <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="profiles.length" :key="currentPage">
                <div class="media text-muted pt-3" v-for="profile in profiles" :key="profile.id">
                    <img class="mr-2 rounded" :src="profile.avatar" :alt="profile.name">
                    <div class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray" :style="hasCharacter(profile.id) ? { position: 'relative' } : {}">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <strong class="text-rpg-primary">
                                <router-link :to="{ name: 'profiles.show', params: { id: profile.id, seo: profile.seo } }" v-if="currentGame.profile_show">
                                    {{ profile.name }}
                                </router-link>
                                <span v-else>
                                    {{ profile.name }}
                                </span>
                            </strong>
                        </div>
                        <span class="d-block" v-if="profile.factionColorTitle" v-html="profile.factionColorTitle"></span>
                        <span class="d-block" v-if="profile.positionTitle">{{ profile.positionTitle }}</span>
                        <span class="d-block" v-if="profile.speciesTitle">{{ profile.speciesTitle }}{{ profile.ageInfo && profile.ageInfo !== trans('age.unknown') ? ' · ' + profile.ageInfo : '' }}</span>

                        <span class="d-block" v-if="isMaster">
                            <strong>{{ trans('location.current_label') }}: </strong>
                                <router-link :to="{ name: 'location', params: { currentLocationKey: profile.location.key } }" v-if="profile.location">
                                    {{ profile.location.title }}
                                </router-link>
                                <span v-else>{{ trans('location.unknown') }}</span>
                        </span>

                        <div class="float-right" style="position:absolute;right:0;top:0;" v-if="currentCharacter && currentCharacter.key === profile.id">
                          <router-link class="btn btn-rpg-primary" :to="{ name: 'profile.index' }" role="button" :title="trans('edit.button')"><font-awesome-icon icon="pencil"></font-awesome-icon></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="!profiles.length">
            {{ trans('profile.no_profiles') }}
        </div>

        <div class="row justify-content-center" v-if="profiles && lastPage > 1">
            <b-pagination-nav :link-gen="linkGen" :number-of-pages="lastPage" use-router></b-pagination-nav>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: "ProfilesList",
        components: {
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                profiles: [],
                lastPage: 0,
                page: 1
            }
        },
        computed: {
            breadcrumb() {
                return [
                        { route: { name: 'main' }, title: this.trans('title')},
                        { title: this.trans('profiles.title') }
                    ];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            currentPage () {
                return this.$route.query && this.$route.query.page ? this.$route.query.page : 1
            }
        },
        created() {
            this.loadProfiles(this.currentPage);
        },
        watch: {
            currentGame(newGame, oldGame) {
                this.loadProfiles(this.currentPage);
            },
            // Пока не следим - в зависимости от того, приходит ли вся инфа, нужная мастеру
            //currentCharacter(newCharacter, oldCharacter) {
                //this.loadProfiles();
            //}
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // Но не до, а после
            // не забываем вызвать next()
            this.loadProfiles(to.query && to.query.page ? to.query.page : 1);
            next();
        },
        methods: {
            hasCharacter (key) {
                return this.$store.getters['rpg/hasCharacter'](key);
            },
            loadProfiles(pageNum = 1) {
                this.$store.dispatch('loading/start', 'Загрузка персонажей...', { root: true });

                this.axios.get('/api/rpg/profiles/' + this.currentGame.key + this.linkGen(pageNum))
                    .then((response) => {
                        //console.log(response.data);
                        // context.dispatch('initUser', {
                        //     user: response.data
                        // });
                        // Значения, так как в паджинаторе нам передаются ключи не от первого для других страниц, а js считает это уже объектом
                        this.profiles = Object.values(response.data.data);
                        this.lastPage = response.data.last_page;
                        this.$store.dispatch('loading/stop', null, { root: true });
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                        // context.rootState.router.push('login');
                    });
            },
            linkGen(pageNum) {
                return pageNum === 1 ? '?' : `?page=${pageNum}`
            },
            trans (key) {
                return this.$t(key);
            }
        }
    }
</script>

<style scoped>
    .list-item {
        display: block;
    }
    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }
</style>
