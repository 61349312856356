<template>
    <div id="app" :class="appClass" :style="appStyle">
        <transition name="fade" v-if="backgroundImage">
            <img :src="backgroundImage" :key="backgroundImage" data-toggle="tooltip" data-placement="bottom" class="rpg-bg">
        </transition>

        <red-navigation ref="redNavigation"></red-navigation>

        <b-modal
                v-model="isError"
                centered
                title="Ошибка"
                header-bg-variant="danger"
                header-text-variant="light"
        >
            <p class="my-4">{{ errorMessage }}</p>
            <div slot="modal-footer" class="w-100">
                <b-button
                        variant="danger"
                        class="float-right"
                        @click="hideError"
                >
                    Закрыть
                </b-button>
            </div>
        </b-modal>

        <div id="draggable-container">
            <ChatChannels></ChatChannels>
            <ChatWindow v-for="(channel, index) in windowChatChannels" :channel="channel" :key="index"></ChatWindow>
        </div>

        <div class="container rpg-main-container">
          <b-overlay :show="isLoading" :no-center="showFixedOverlay" variant="white" rounded="sm">
            <template #overlay>
              <div class="text-center" :style="overlayIconStyle">
                <b-spinner></b-spinner>
                <p v-html="loadingMessage"></p>
              </div>
            </template>
            <main role="main" class="container bg-rpg-panel rounded box-shadow mb-1 mt-5 pb-1 pt-1 rpg-main">
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </main>
          </b-overlay>
        </div>
    </div>
</template>

<script>
    import Navigation from "./components/includes/Navigation";
    import ChatWindow from "./components/ChatWindow";
    import ChatChannels from "./components/ChatChannels";

    export default {
        name: "App",
        components: {
            ChatChannels,
            ChatWindow,
            RedNavigation: Navigation
        },
        computed: {
            isLoading() {
                return this.$store.getters['loading/isLoading'];
            },
            loadingMessage() {
                return this.$store.getters['loading/getLoadingMessage'];
            },
            errorMessage() {
                return this.$store.getters['loading/getErrorMessage'];
            },
            isError: {
                // геттер:
                get: function () {
                    return this.$store.getters['loading/isError'];
                },
                // сеттер:
                set: function (newValue) {
                    // Тут может только становиться false
                    this.hideError();
                }
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            windowChatChannels () {
                return this.$store.getters['chat/windowChannels'];
            },

            appClass() {
                return {
                    //'rpg-bg': !!this.backgroundImage
                }
            },

            appStyle() {
                return {
                    //backgroundImage: this.backgroundImage ? 'url(' + this.backgroundImage + ')' : null,
                    //minHeight: this.$refs && this.$refs.redNavigation ? 'calc( 100vh - ' +  this.$refs.redNavigation.clientHeight  + ')' : '100vh'
                }
            },

            // Если мы в большом чате и выбран канал - возможно, у него есть фон
            currentChannel () {
                return this.$store.getters['chat/channel'];
            },

            rpgBackground () {
                return this.$store.getters['rpg/background'];
            },

            backgroundImage() {
                if (this.rpgBackground) {
                    return this.rpgBackground;
                } else if (this.currentChannel && this.currentChannel.bgimage) {
                    return this.currentChannel.bgimage;
                } else if (this.currentGame) {
                    return this.currentGame.bgimage;
                }
                return null;
            },

            showFixedOverlay () {
                return this.clientHeight < this.scrollHeight;
            },

            overlayIconStyle () {
                return {
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',
                  width: '500px'
                }
            }
        },
        methods: {
            hideError() {
                this.$store.dispatch('loading/hideError');
            },
            setHeight() {
              this.scrollHeight = Math.max(
                  document.body.scrollHeight, document.documentElement.scrollHeight,
                  document.body.offsetHeight, document.documentElement.offsetHeight,
                  document.body.clientHeight, document.documentElement.clientHeight
              );

              this.clientHeight = document.documentElement.clientHeight;
            }
        },
        data() {
          return {
            scrollHeight: 0,
            clientHeight: 0
          }
        },
        watch: {
            isLoading() {
              this.$nextTick(() => {
                this.setHeight();
              })
            }
        },
        created() {
            //this.$store.commit('user/setUser', this.user);
            //this.$store.dispatch('auth/initAuth');
            //this.$store.dispatch('chat/initChat');

            // Для минимальной высоты. Просто мобильный хром не хочет нормально 100vh считать
            // Тут, потому что считается не для vue, а для корня с учетом окна
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            this.setHeight();

            // We listen to the resize event
            window.addEventListener('resize', () => {
                // We execute the same script as before
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
                this.setHeight();
            });
        },
        mounted() {
            /**
             * Попытка решения проблемы пустой страницы в мобильном сафари с айпада.
             * https://github.com/vuejs/vue/issues/5533
             */
            this.$nextTick(() => {
                window.scrollTo(0, 1)
                window.scrollTo(0, 0)
            })
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>
