<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon icon="user" size="2x"></font-awesome-icon></span>
            <div class="lh-100 flex-grow-1">
                <h4 class="mb-0 redfont lh-100">{{ $t('profile.title') }}</h4>
            </div>
            <router-link class="btn btn-outline-rpg-light float-right" :to="{ name: 'profile.create' }" role="button" :title="$t('create.title')" v-if="currentGame.profile_create">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon> {{ $t('create.title') }}
            </router-link>
            <router-link class="btn btn-outline-rpg-light float-right" :to="{ name: 'profiles.choose' }" role="button" :title="$t('profiles.choose')" v-if="currentGame.profile_choose && isMaster">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon> {{ $t('profiles.choose') }}
            </router-link>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <div :class="'my-3 p-3 bg-rpg-light' + (profile.confirmed ? '' : ' border border-danger') + ' rounded box-shadow'" v-if="profile">
            <div class="media">
                <div class="align-self-start mr-3">
                    <avatar-upload
                        :old-image="profile.image"
                        :old-value="profile.imageValue"
                        v-if="currentGame.avatar_change || !profile.confirmed"
                    ></avatar-upload>
                    <b-avatar :src="profile.image" rounded size="6rem" v-else></b-avatar>
                </div>
                <div class="media-body">
                    <div class="float-right">
                        <router-link v-if="!profile.confirmed || currentGame.profile_edit" :to="{ name: 'profile.edit' }" class="btn btn-rpg-primary" role="button">
                            <font-awesome-icon icon="pencil"></font-awesome-icon> {{ $t('edit.button') }}
                        </router-link>
                    </div>
                    <h5 class="mt-0" v-html="profile.factionColorTitle"></h5>
                    <img class="img-thumbnail mr-1" :src="profile.factionImage" :alt="profile.factionTitle" v-if="profile.factionTitle">
                    <img class="img-thumbnail mr-1" :src="profile.speciesImage" :alt="profile.speciesTitle" v-if="profile.speciesTitle">
                    <img class="img-thumbnail mr-1" :src="profile.positionImage" :alt="profile.positionTitle" v-if="profile.positionTitle">
                </div>
            </div>

            <profile-comments :comments="comments" v-if="!profile.confirmed && comments"></profile-comments>

            <div class="form-group row" v-if="profile.alias">
                <label for="alias" class="col-sm-2 col-form-label">{{ $t('form.alias') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="alias">{{ profile.alias }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.factionColorTitle">
                <label for="faction" class="col-sm-2 col-form-label">{{ $t('form.faction') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="faction" v-html="profile.factionColorTitle"/>
                </div>
            </div>

            <div class="form-group row" v-if="profile.speciesTitle">
                <label for="species" class="col-sm-2 col-form-label">{{ $t('form.species') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="species">{{ profile.speciesTitle }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.positionTitle">
                <label for="position" class="col-sm-2 col-form-label">{{ $t('form.position') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="position">{{ profile.positionTitle }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.genderInfo">
                <label for="bag" class="col-sm-2 col-form-label">{{ $t('form.gender') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="gender"><font-awesome-icon :icon="['fas', profile.genderIcon]" size="lg" v-if="profile.genderIcon"></font-awesome-icon> {{ profile.genderInfo }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.ageInfo && profile.ageInfo !== $t('age.unknown')">
                <label for="age" class="col-sm-2 col-form-label">{{ $t('form.age') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="age">{{ profile.ageInfo }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.activity">
                <label for="activity" class="col-sm-2 col-form-label">{{ $t('form.activity') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="activity">{{ profile.activity }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.look">
                <label for="look" class="col-sm-2 col-form-label">{{ $t('form.look') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="look">{{ profile.look }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.temper">
                <label for="temper" class="col-sm-2 col-form-label">{{ $t('form.temper') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="temper">{{ profile.temper }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.biography">
                <label for="biography" class="col-sm-2 col-form-label">{{ $t('form.biography') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="biography">{{ profile.biography }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.skills">
                <label for="skills" class="col-sm-2 col-form-label">{{ $t('form.skills') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="skills">{{ profile.skills }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.bag">
                <label for="bag" class="col-sm-2 col-form-label">{{ $t('form.bag') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="bag">{{ profile.bag }}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="location" class="col-sm-2 col-form-label">{{ $t('location.current_label') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="location">
                        <router-link :to="{ name: 'location', params: { currentLocationKey: profile.location.key } }" v-if="profile.location">
                            {{ profile.location.title }}
                        </router-link>
                        <span v-else>{{ $t('location.unknown') }}</span>

                        <div class="float-right" v-if="isMaster && false">
                            <a class="btn btn-rpg-primary" href="route('rpg.profile.move', [ 'id' => $profile->id])" role="button"><font-awesome-icon icon="map"></font-awesome-icon> {{ $t('master.move') }}</a>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else-if="isMaster">
            {{ $t('profiles.master_choose') }}
        </div>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else>
            {{ $t('profile.no_profiles') }}
        </div>
    </div>

</template>

<script>
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import AvatarUpload from "../../components/includes/AvatarUpload";
    import ProfileComments from "../../components/ProfileComments";

    export default {
        name: "ProfileIndex",
        components: {
            ProfileComments,
            AvatarUpload,
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                profile: null,
                comments: null
            }
        },
        computed: {
            breadcrumb() {
                return [
                    { route: { name: 'main' }, title: this.$t('title')},
                    { title: this.$t('profile.title')}
                ];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            isUserCharacter () {
                return this.$store.getters['rpg/isUserCharacter'];
            },
        },
        created() {
            this.loadProfile();
        },
        watch: {
            // currentGame(newGame, oldGame) {
            //     this.loadProfiles();
            // },
            // Не сразу будет доступно, если страница только открылась
            currentCharacter(newCharacter, oldCharacter) {
                // Только при изменении ключа (чтобы не перегружалось при изменении инфы после смены аватара)
                if (!oldCharacter || !newCharacter || (oldCharacter.key !== newCharacter.key)) {
                    this.loadProfile();
                }
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.loadProfile();
            next();
        },
        methods: {
            hasCharacter (key) {
                return this.$store.getters['rpg/hasCharacter'](key);
            },
            loadProfile() {
                if (!this.currentCharacter || this.isMaster || this.isUserCharacter) {
                    this.profile = null;
                    return;
                }

                this.$store.dispatch('loading/start', 'Загрузка персонажа...', { root: true });

                this.axios.get('/api/rpg/profile/' + this.currentGame.key + '/' + this.currentCharacter.key)
                    .then((response) => {
                        // context.dispatch('initUser', {
                        //     user: response.data
                        // });
                        this.profile = response.data.profile;
                        this.comments = response.data.comments;
                        this.$store.dispatch('loading/stop', null, { root: true });
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                        // context.rootState.router.push('login');
                    });
            }
        }
    }
</script>

<style scoped>

</style>
