<template>
    <div>
        <div class="card-rpg-header my-3 p-3 text-rpg-light bg-rpg-primary rounded box-shadow" v-if="location">
            <div class="media d-block d-md-flex">
                <img class="d-flex mb-3 mx-auto rounded" :src="location.image" :alt="location.title">
                <div class="media-body ml-md-3 ml-0">
                    <h5 class="mt-0 text-center text-md-left redfont">{{ location.title }}</h5>
                    <span class="turn-content" v-html="location.desc"/>
                </div>
            </div>
            <b-avatar-group rounded="circle" size="40px" v-if="locationCharacters">
                <b-avatar :badge="isOnline(char)" variant="secondary" badge-variant="success" :src="char.img" :alt="char.name" v-for="char in locationCharacters" :key="char.id" v-b-tooltip.hover.top="{ title: char.name, variant: 'secondary',  boundary: 'window' }" class="channel-image small-badge" :to="char.type === 'profile' && currentGame.profile_show ? { name: 'profiles.show', params: { id: char.id, seo: char.seo } } : null" :href="char.type === 'user' && char.url ? char.url : null"></b-avatar>
            </b-avatar-group>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <ul class="nav nav-tabs nav-pills pt-3" v-if="location">
            <li class="nav-item">
                <router-link class="nav-link"  active-class="active" :to="{ name: 'location', params: { currentLocationKey: location.key, all: null } }" exact>
                    {{ trans('turn.last_turns') }}
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link"  active-class="active" :to="{ name: 'location', params: { currentLocationKey: location.key, all: 'all' } }">
                    {{ trans('turn.all_turns') }}
                </router-link>
            </li>
            <li class="nav-item ml-auto">
                <a class="btn btn-outline-rpg-primary" @click="order = (order === 'desc' ? 'asc' : 'desc')" data-toggle="tooltip" :title="trans('turn.change_order')">
                    <font-awesome-icon class="mx-auto" :icon="order === 'desc' ? 'sort-amount-up' : 'sort-amount-down'" size="lg"></font-awesome-icon>
                </a>
            </li>
        </ul>

        <make-turn
            v-if="showMakeTurn"
            save-suffix="turn"
            :page-location="location.key"
            @sent="fetchNewTurnsNow"
        ></make-turn>

        <transition-group name="list" tag="div">
          <turn v-for="turn in orderedTurns" :turn="turn" :key="turn.id" @deleted="deleteTurn" @edited="editTurn" :show-location="false"></turn>
        </transition-group>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="!orderedTurns.length">
            {{ trans('location.no_turns') }}
        </div>

        <div class="row justify-content-center" v-if="orderedTurns && orderedTurns.length && lastPage > 1">
            <b-pagination-nav
                    :link-gen="linkGen"
                    :number-of-pages="lastPage"
                    use-router
                    hide-goto-end-buttons
            >
                <template v-slot:prev-text><font-awesome-icon icon="arrow-left"><i class="fas fa-arrow-left"></i></font-awesome-icon></template>
                <template v-slot:next-text><font-awesome-icon icon="arrow-right"><i class="fas fa-arrow-right"></i></font-awesome-icon></template>
            </b-pagination-nav>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Turn from "../../components/Turn";
    import MakeTurn from "@/components/MakeTurn";
    import {newTurnsSimpleMixin} from "@/mixins/newTurnsSimpleMixin";

    export default {
        name: "LocationShow",
        mixins: [
          newTurnsSimpleMixin
        ],
        components: {
            Turn,
            FontAwesomeIcon,
            Breadcrumb,
            MakeTurn
        },
        data() {
            return {
                location: null,
                turns: [],
                lastPage: 0,
                order: 'desc',
                currentPage: 1
            }
        },
        computed: {
            isAll() {
                return this.$route.params && this.$route.params.all === 'all';
            },

            orderedTurns() {
                if (!this.turns) {
                    return [];
                }
                return this.order === 'desc' ? this.turns : this.turns.slice().reverse();
            },

            breadcrumb() {
                let breadcrumb = [
                    { route: { name: 'main' }, title: this.trans('title')}
                ];

                if (this.location) {
                    if (this.location.breadcrumb) {
                        // Тут только карты
                        this.location.breadcrumb.forEach(function (bread) {
                            breadcrumb.push({ route: { name: 'map', params: { currentMapKey: bread.key } }, title: bread.title });
                        })
                    }
                }
                breadcrumb.push({ title: this.location ? this.location.title : this.trans('location.title')});

                return breadcrumb;
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            gameLocations () {
              return this.$store.getters['rpg/locations'];
            },

            /**
             * Возможные значения для локаций.
             * Либо одно из базовых значений (если текущая локация еще не выбрана или если есть флаг игры).
             * Либо только одно текущее.
             */
            currentLocations () {
              if (this.isMaster) { // todo - Пока для всех мастеров и игротехов отображается, поправить для вывода NPC уже внутри
                return Object.values(this.gameLocations);
              } else if (!this.currentCharacter.location || this.currentGame.choose_turn_location) {
                return this.currentGame.baseLocations.map(locationKey => this.locationInfo(locationKey)).filter(location => !!location);
              } else if (this.currentCharacter.location) {
                return [this.locationInfo(this.currentCharacter.location)];
              } else {
                return [];
              }
            },

            showMakeTurn() {
              return this.currentCharacter && this.location && this.currentLocations.map(location => location.key).indexOf(this.location.key) !== -1;
            },

            /**
             * Нужно ли в принципе грузить новые сообщения
             * @returns {boolean}
             */
            needListenNewTurns() {
              return this.currentPage === 1 && this.location;
            },

            /**
             * API URL для загрузки новых ходов
             * @returns {string}
             */
            newTurnsApiUrl() {
              return '/api/rpg/location/' + this.currentGame.key + '/' + this.location.key + '/new';
            },

            locationCharacters() {
                return this.location && this.location.characters ? this.location.characters : [];
            }
        },
        created() {
            this.loadLocation(this.$route.query && this.$route.query.page ? this.$route.query.page : 1, this.$route.params.currentLocationKey, this.$route.params.all);
        },
        watch: {
            currentGame(newGame, oldGame) {
                this.loadLocation(this.$route.query && this.$route.query.page ? this.$route.query.page : 1, this.$route.params.currentLocationKey, this.$route.params.all);
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.loadLocation(to.query && to.query.page ? to.query.page : 1, to.params.currentLocationKey, to.params.all);
            next();
        },
        beforeDestroy() {
          // На всякий случай завершаем и тут
          if (this.currentGame && this.location) {
            this.leave(this.currentGame.key, this.location.key);
          }
        },
        methods: {
            hasCharacter (key) {
                return this.$store.getters['rpg/hasCharacter'](key);
            },
            loadLocation(pageNum, currentLocationKey, all = null) {
                if (this.location && this.currentPage === 1) {
                  // Если была локация, и на первой странице - отключаемся
                  this.leave(this.currentGame.key, this.location.key);
                }

                this.currentPage = pageNum;
                this.location = null;
                this.turns = [];
                this.$store.dispatch('loading/start', 'Загрузка локации...', { root: true });

                this.axios.get('/api/rpg/location/' + this.currentGame.key + '/' + currentLocationKey + (all ? '/' + all : '') + this.linkGen(pageNum))
                    .then((response) => {
                        this.location = response.data.location;
                        if (all) {
                            this.turns = response.data.turns.data;
                            this.lastPage = response.data.turns.last_page;
                        } else {
                            this.turns = response.data.turns;
                            this.lastPage = 1;
                        }

                        // Иногда приходит не массив, а объект?
                        if (this.turns instanceof Object) {
                            this.turns = Object.values(this.turns);
                        }

                        this.$store.dispatch('loading/stop', null, { root: true });

                        if (this.location.bgimage) {
                            this.$store.dispatch('rpg/setBackground', this.location.bgimage);
                        }

                        // Подключаемся, если первая страница
                        if (this.currentPage === 1) {
                            this.listen(this.currentGame.key, this.location.key);
                        }
                    }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                });
            },
            linkGen(pageNum) {
                return pageNum === 1 ? '?' : `?page=${pageNum}`
            },
            trans (key) {
                return this.$t(key);
            },
            locationInfo (locationKey) {
              return locationKey ? this.$store.getters['rpg/location'](locationKey) : {};
            },

            /**
             * Включить прослушивание сокетов для ходов игры.
             */
            listen(gameKey, locationKey) {
              Echo.channel(`rpg.game.${gameKey}.${locationKey}.turn`)
                  .listen('TurnSent', event => {
                    this.addTurn(event.turn);
                  })
                  .listen('TurnDeleted', event => {
                    this.deleteTurn(event.turn);
                  })
                  .listen('TurnUpdated', event => {
                    this.editTurn(event.turn);
                  });
              this.listenSimple();
            },

            addTurn(turn) {
              this.turns.splice(0, 0, turn);
            },

            editTurn(turn) {
                var turnIndex = this.turns.findIndex(currentTurn => currentTurn.id === turn.id);
                if (turnIndex !== -1) {
                    this.turns.splice(turnIndex, 1, turn);
                }
            },

            deleteTurn(turn) {
                var turnIndex = this.turns.findIndex(currentTurn => currentTurn.id === turn.id);
                if (turnIndex !== -1) {
                    this.turns.splice(turnIndex, 1);
                }
            },

            /**
             * Выключить прослушивание сокетов для игры.
             */
            leave(gameKey, locationKey) {
              Echo.leave(`rpg.game.${gameKey}.${locationKey}.turn`);
              this.leaveSimple();
            },

            isOnline (char) {
                return this.$store.getters['rpg/isOnline'](char.id, char.type);
            },
        }
    }
</script>

<style scoped>
.list-item {
  display: block;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
