<template>
    <b-modal
            :id="modalId"
            ref="modal"
            :title="trans('dice.roll_title')"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-title="trans('dice.roll_title')"
            :cancel-title="trans('dice.cancel')"
    >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
                    :label="count + ' ' + ( count > 1 ? trans('dice.dices_title') : trans('dice.dice_title')) + ' d' + dice"
                    label-for="dice-input"
            >
                <b-input-group
                        id="mod-input"
                >
                    <b-input-group-prepend style="width: 5rem;">
                        <b-form-input
                                v-model="count"
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                        ></b-form-input>
                    </b-input-group-prepend>
                    <b-form-input
                            id="dice-input"
                            type="range"
                            min="2"
                            max="20"
                            step="1"
                            v-model="dice"
                            required
                    ></b-form-input>
                </b-input-group>
            </b-form-group>

            <b-form-group
                    :label="trans('dice.with_mod') + ' ' + modType + mod"
                    label-for="mod-input"
            >
                <b-input-group
                    id="mod-input"
                >
                    <b-input-group-prepend>
                        <b-input-group-text :class="modTypeSwitch ? 'bg-success' : 'bg-danger'">
                            <b-form-checkbox v-model="modTypeSwitch" switch class="mr-n2">
                                <span class="sr-only">+ / -</span>
                            </b-form-checkbox>
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                            v-model="mod"
                            type="number"
                            min="0"
                            max="100"
                            step="1"
                    ></b-form-input>
                </b-input-group>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
    export default {
        name: "RollDiceWindow",
        props: {
            modalId: { required: true },
            sendRoll: { default: (rollCommand, closeCallback) => {} }
        },
        data() {
            return {
                count: 1,
                dice: 20,
                mod: 0,
                modTypeSwitch: true
            }
        },
        computed: {
            modType() {
                return this.modTypeSwitch ? '+' : '-';
            },
            rollCommand() {
                return '/' + (this.count > 1 ? this.count : '') +'d' + this.dice + (this.mod !== 0 ? this.modType + this.mod : '');
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            }
        },
        methods: {
            trans (key) {
                return this.$t(key);
            },

            resetModal() {
                this.dice = 20;
                this.mod = 0;
                this.modTypeSwitch = true;
            },
            checkFormValidity() {
                return this.$refs.form.checkValidity();
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }

                this.rollDice();
            },

            rollDice() {
                this.sendRoll(
                    this.rollCommand,
                    () => {
                        // Hide the modal manually
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                        })
                    }
                );
            }
        }
    }
</script>
