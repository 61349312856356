<template>
    <transition name="fade" mode="out-in">
        <router-view></router-view>
    </transition>
</template>

<script>
    export default {
        name: "Game",
        created() {
            this.setGameKey(this.$route.params.game);
        },
        beforeRouteUpdate (to, from, next) {
            this.setGameKey(to.params.game);
            next();
        },
        methods: {
            setGameKey(gameKey) {
                if (this.$store.getters['rpg/games'].hasOwnProperty(gameKey)) {
                    this.$store.dispatch('rpg/setGame', gameKey);
                } else {
                    this.$router.push({ name: 'index' });
                }
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>
