<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon icon="user" size="2x"></font-awesome-icon></span>
            <div class="lh-100 flex-grow-1">
                <h4 class="mb-0 redfont lh-100">{{ $t('edit.title') }}</h4>
            </div>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <loading v-if="isLoading && !profile"></loading>
        <profile-form v-model="profile" v-else-if="profile" :species="species" :factions="factions" :genders="genders" :positions="positions" :comments="comments" :submit="submit" :button-title="$t('edit.send')" :errors="errors"></profile-form>
        <div v-else>
            {{ $t('error.no_data') }}
        </div>
    </div>
</template>

<script>
    import ProfileForm from "../../components/ProfileForm";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import Loading from "../../components/includes/Loading";
    export default {
        name: "ProfileEdit",
        components: {
            Loading,
            ProfileForm,
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                profile: null,
                species: [],
                factions: [],
                genders: [],
                positions: [],
                comments: [],
                errors: null
            }
        },
        computed: {
            breadcrumb() {
                return [
                    { route: { name: 'main' }, title: this.$t('title')},
                    { route: { name: 'profile.index' }, title: this.$t('profile.title')},
                    { title: this.$t('edit.title') }
                ];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            isUserCharacter () {
                return this.$store.getters['rpg/isUserCharacter'];
            },

            isLoading () {
                return this.$store.getters['loading/isLoading'];
            },
        },
        created() {
            this.loadProfile();
        },
        watch: {
            // currentGame(newGame, oldGame) {
            //     this.loadProfiles();
            // },
            // Не сразу будет доступно, если страница только открылась
            currentCharacter(newCharacter, oldCharacter) {
                this.loadProfile();
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.loadProfile();
            next();
        },
        methods: {
            loadProfile() {
                this.profile = null;
                if (!this.currentCharacter || this.isMaster || this.isUserCharacter) {
                    this.profile = null;
                    return;
                }

                this.$store.dispatch('loading/start', 'Загрузка персонажа...', { root: true });

                this.axios.get('/api/rpg/profile/' + this.currentGame.key + '/' + this.currentCharacter.key + '/edit')
                    .then((response) => {
                        // context.dispatch('initUser', {
                        //     user: response.data
                        // });
                        this.profile = response.data.profile;
                        this.species = response.data.species;
                        this.factions = response.data.factions;
                        this.genders = response.data.genders;
                        this.positions = response.data.positions;
                        this.comments = response.data.comments;
                        this.$store.dispatch('loading/stop', null, { root: true });
                    }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                    // context.rootState.router.push('login');
                });
            },

            submit: function (profile) {
                this.errors = null;
                this.$store.dispatch('loading/start', 'Сохранение персонажа...', { root: true });
                this.axios.post('/api/rpg/profile/' + this.currentGame.key + '/' + this.currentCharacter.key + '/edit', profile).then( (response) => {
                    this.$store.dispatch('loading/stop', null);
                    this.$store.dispatch('rpg/setCharacterData', response.data.profile);
                    this.$store.dispatch('message/success', response.data.message);
                    this.$router.push({ name: 'profile.index' });
                }).catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$store.dispatch('loading/stop', null);
                });
            },
        }
    }
</script>

<style scoped>

</style>
