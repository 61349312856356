<template>
    <div class="my-3 p-3 bg-rpg-light rounded box-shadow">
        <b-card-group columns>
            <b-card :img-src="game.image" :img-alt="game.title" img-top v-for="game in games" :key="game.key" class="card-choose" @click="setGame(game.key)" v-if="game.is_show_menu">
                <b-card-title v-html="game.title"/>
                <b-card-text v-html="game.body"/>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
    export default {
        name: "Index",
        created() {


            let settingsGame = this.$store.getters['auth/setting']('game');
            if (settingsGame) {
                this.$router.push({ name: 'main', params: { game: settingsGame } });
            }
        },
        computed: {
            games () {
                return this.$store.getters['rpg/games'];
            },
        },
        methods: {
            setGame(key) {
                this.$router.push({ name: 'main', params: { game: key } });
            }
        }
    }
</script>

<style scoped>

</style>
